import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/front/auth/Login.auth.vue'
import Register from '../views/front/auth/Register.auth.vue'
import Logout from '../views/front/auth/Logout.auth.vue'
import Dashboard from '../views/back/Dashboard.back'

import AddSeller from '../views/back/seller/Add.seller'
import ListSellers from '../views/back/seller/List.seller'
import EditSeller from '../views/back/seller/Edit.seller'

import AddCategory from '../views/back/category/Add.category'
import ListCategories from '../views/back/category/List.category'
import EditCategory from '../views/back/category/Edit.category'

import AddClient from '../views/back/client/Add.client'
import ListClients from '../views/back/client/List.client'
import EditClient from '../views/back/client/Edit.client'

import AddProduct from '../views/back/product/Add.product'
import ListProducts from '../views/back/product/List.product'
import EditProduct from '../views/back/product/Edit.product'

import BSProduct from '../views/back/product/BestSelling.product'

import Stock from '../views/back/product/Stock.product'
import EditStock from '../views/back/product/Edit.stock'

import AddStore from '../views/back/store/Add.store'
import ListStores from '../views/back/store/List.store'
import EditStore from '../views/back/store/Edit.store'

import ListDettes from '../views/back/store/dette/List.dette'
import EditDette from '../views/back/store/dette/Edit.dette'

import AddSupplier from '../views/back/supplier/Add.supplier'
import ListSuppliers from '../views/back/supplier/List.supplier'
import EditSupplier from '../views/back/supplier/Edit.supplier'

import AddExpense from '../views/back/seller/expense/Add.expense.seller'
import ListExpenses from '../views/back/seller/expense/List.expense.seller'
import EditExpense from '../views/back/seller/expense/Edit.expense.seller'

import PosDashboard from '../views/back/pos/Dashboard.pos'

// Order
import ListOrder from '../views/back/order/List.order'
import ViewOrder from '../views/back/order/View.order'
import SearchOrder from '../views/back/order/Search.order'

// Incomes
import StoreIncome from '../views/back/store/income/Income.store'
import AddDeposit from '../views/back/store/deposit/AddDeposit.store'
import EditDeposit from '../views/back/store/deposit/EditDeposit.store'
import ListDeposits from '../views/back/store/deposit/ListDeposit.store'


const routes = [
  { path: '/', name: 'Login', component: Login },
  { path: '/register', name: 'Register', component: Register },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/logout', name: 'Logout', component: Logout },

  { path: '/add-seller', name: 'Add-Seller', component: AddSeller },
  { path: '/sellers', name: 'List-Sellers', component: ListSellers },
  { path: '/edit-seller/:id', name: 'Edit-Seller', component: EditSeller },

  { path: '/add-category', name: 'Add-Category', component: AddCategory },
  { path: '/categories', name: 'List-Categories', component: ListCategories },
  { path: '/edit-category/:id', name: 'Edit-Category', component: EditCategory },

  { path: '/add-client', name: 'Add-Client', component: AddClient },
  { path: '/clients', name: 'List-Clients', component: ListClients },
  { path: '/edit-client/:id', name: 'Edit-Client', component: EditClient },

  { path: '/add-product', name: 'Add-Product', component: AddProduct },
  { path: '/products', name: 'List-Products', component: ListProducts },
  { path: '/edit-product/:id', name: 'Edit-Product', component: EditProduct },
  { path: '/stock', name: 'Stock', component: Stock },
  { path: '/edit-stock/:id', name: 'Edit-Stock', component: EditStock },

  { path: '/best-selling-products', name: 'BSProduct', component: BSProduct },

  { path: '/add-store', name: 'Add-Store', component: AddStore },
  { path: '/stores', name: 'List-Stores', component: ListStores },
  { path: '/edit-store/:id', name: 'Edit-Store', component: EditStore },
  { path: '/store/income', name: 'StoreIncome', component: StoreIncome },

  { path: '/store/add-deposit', name: 'AddDeposit', component: AddDeposit },
  { path: '/store/edit-deposit/:id', name: 'Edit-Deposit', component: EditDeposit },
  { path: '/store/deposits', name: 'ListDeposits', component: ListDeposits },

  { path: '/store/edit-dette/:id', name: 'Edit-Dette', component: EditDette },
  { path: '/store/dettes', name: 'List-Dettes', component: ListDettes},

  { path: '/add-supplier', name: 'Add-Supplier', component: AddSupplier },
  { path: '/suppliers', name: 'List-Suppliers', component: ListSuppliers },
  { path: '/edit-supplier/:id', name: 'Edit-Supplier', component: EditSupplier },

  { path: '/add-expense', name: 'Add-Expense', component: AddExpense },
  { path: '/expenses', name: 'List-Expense', component: ListExpenses },
  { path: '/edit-expense/:id', name: 'Edit-Expense', component: EditExpense },

  { path: '/pos', name: 'PosDashboard', component: PosDashboard },
  { path: '/orders', name: 'List-Order', component: ListOrder },

  { path: '/view-order/:id', name: 'View-Order', component: ViewOrder },
  { path: '/order/search', name: 'Search-Order', component: SearchOrder },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
