<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container-fluid">
				<div class="row mb-2">
					<div class="col-sm-6">
						<router-link to="/stock" class="btn btn-primary"
							><i class="fas fa-arrow-left"></i> Voir les produuits en
							stock</router-link
						>
					</div>
					<div class="col-sm-6">
						<ol class="breadcrumb float-sm-right">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">Home</router-link>
							</li>
							<li class="breadcrumb-item active">MAJ Stock</li>
						</ol>
					</div>
				</div>
			</div>
			<!-- /.container-fluid -->
		</section>
		<div class="col-md-8 m-auto">
			<!-- general form elements -->
			<div class="card card-primary">
				<div class="card-header">
					<h3 class="card-title">Ajout d'un produit</h3>
				</div>
				<!-- /.card-header -->
				<!-- form start -->
				<form @submit="updateStock" enctype="multipart/form-data">
					<div class="card-body">
						<div class="row">
							<div class="col-sm-12">
								<div class="form-group">
									<label for="exampleInputPassword1"
										>Quantité <span style="color: red">*</span></label
									>
									<input
										type="number"
										class="form-control"
										id="exampleInputPassword1"
										placeholder="Entrer la quantité"
										min="0"
										v-model="form.quantity"
									/>
								</div>
							</div>
						</div>
					</div>
					<!-- /.card-body -->

					<div class="card-footer">
						<button type="submit" class="btn btn-primary">Ajouter</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import api from "../../../api/users";
import { Toast } from "../../../alert/SweetAlert";
import AppStorage from "../../../helpers/AppStorage";

export default {
	name: "EditStock",
	data() {
		return {
			form: {
				quantity: 0,
			},
			errors: {},
			token: "",
		};
	},
	methods: {
		async updateStock(e) {
			e.preventDefault();
			if (this.form.quantity > 0) {
				try {
					let id = this.$route.params.id;

					const res = await api.patch(
						"/api/product/" + id,
						JSON.stringify(this.form),
						{
							headers: { Authorization: `Bearer ${this.token}` },
						}
					);
					Toast.fire({
						icon: "success",
						title: "Produit modifié",
					});
					this.form = {};
				} catch (error) {
					console.log(error);
				}
			} else {
				Toast.fire({
					icon: "warning",
					title: "Veuillez saisir la quantité",
				});
			}
		},
		async getProduct(id) {
			try {
				const res = await api.get("/api/product/" + id, {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				const data = await res.data;
				console.log(data);
				if (data) {
					this.form = data[0];
				}
			} catch (err) {
				console.error(err);
			}
		},
	},
	created() {
		if (!User.isLoggedIn() || !User.isAdmin()) {
			this.$router.push("/");
		} else {
			let id = this.$route.params.id;

			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
			if (this.token) {
				this.getProduct(id);
			}
		}
	},
};
</script>