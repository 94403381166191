<template>
	<!-- Main content -->
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container-fluid">
				<div class="row mb-2">
					<div class="col-sm-6">
						<router-link to="/expenses" class="btn btn-primary"
							>Voir les dépenses</router-link
						>
					</div>
					<div class="col-sm-6">
						<ol class="breadcrumb float-sm-right">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">Home</router-link>
							</li>
							<li class="breadcrumb-item active">Ajout-Dépense</li>
						</ol>
					</div>
				</div>
			</div>
			<!-- /.container-fluid -->
		</section>
		<section class="content">
			<form @submit="addExpense" enctype="multipart/form-data">
				<div class="row">
					<div class="col-md-12">
						<div class="card card-primary">
							<div class="card-header">
								<h3 class="card-title">Dépense</h3>
							</div>
							<div class="card-body">
								<div class="form-group">
									<label for="inputDescription">Détails de la dépense</label>
									<textarea
										id="inputDescription"
										class="form-control"
										rows="4"
										v-model="form.details"
									></textarea>
								</div>
								<div class="form-group">
									<label for="inputSpentBudget">Montant utilisé</label>
									<input
										type="number"
										class="form-control"
										v-model="form.amount"
										min="0"
									/>
								</div>
							</div>
							<!-- /.card-body -->
						</div>
						<!-- /.card -->
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<input type="submit" value="Ajouter la dépense" class="btn btn-success" />
					</div>
				</div>
			</form>
		</section>
		<!-- /.content -->
	</div>
</template>
<script>
import api from "../../../../api/users";
import { Toast } from "../../../../alert/SweetAlert";
import AppStorage from "../../../../helpers/AppStorage";

export default {
	name: "AddExpense",
	data() {
		return {
			form: {
				details: "",
				amount: 0,
				seller_id: null,
			},
			errors: [],
			token: "",
		};
	},
	methods: {
		async addExpense(e) {
			e.preventDefault();
			if (this.form.details.trim() !== "" && this.form.amount > 0) {
				this.errors = [];
				try {
					const res = await api.post("/api/expense", JSON.stringify(this.form), {
						headers: { Authorization: `Bearer ${this.token}` },
					});
					const data = await res.data;
					if (data) {
						Toast.fire({
							icon: "success",
							title: data.message,
						});
						this.form = {};
						this.$router.push('/expenses')
					}
				} catch (error) {
					Toast.fire({
						icon: "warning",
						title: error.response.data.message,
					});
				}
			} else if (this.form.details.trim() === "" && this.form.amount > 0) {
				this.errors = [];
				this.errors.push("Veillez renseigner les details");
				Toast.fire({
					icon: "warning",
					title: "Veillez renseigner les details",
				});
			} else if (this.form.details.trim() !== "" && this.form.amount === 0) {
				this.errors = [];
				this.errors.push("Veillez renseigner le montant");
				Toast.fire({
					icon: "warning",
					title: "Veillez renseigner le montant",
				});
			} else {
				this.errors = [];
				this.errors.push("Veillez remplir tous les champs");
				Toast.fire({
					icon: "warning",
					title: "Veillez remplir tous les champs",
				});
			}
		},
	},
	created() {
		if (!User.isLoggedIn() || User.isAdmin()) {
			this.$router.push("/");
		} else {
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
			if(this.token){
				this.form.seller_id = User.id();
			}
		}
	},
};
</script>