<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container-fluid">
				<div class="row mb-2">
					<div class="col-sm-6">
						<router-link to="/orders" class="btn btn-primary"
							>Voir les catégories</router-link
						>
					</div>
					<div class="col-sm-6">
						<ol class="breadcrumb float-sm-right">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">Home</router-link>
							</li>
							<li class="breadcrumb-item active">Recherche-Commande</li>
						</ol>
					</div>
				</div>
			</div>
			<!-- /.container-fluid -->
		</section>
		<div class="col-md-12 m-auto">
			<!-- general form elements -->
			<div class="card card-primary">
				<div class="card-header">
					<h3 class="card-title">Rechercher des commandes</h3>
				</div>
				<!-- /.card-header -->
				<!-- form start -->
				<form @submit="search" enctype="multipart/form-data">
					<div class="card-body">
						<div class="col-sm-12">
							<!-- text input -->
							<div class="form-group">
								<label>Date de la commande <span style="color: red">*</span></label>
								<input type="date" class="form-control" v-model="form.date" />
							</div>
						</div>
					</div>
					<!-- /.card-body -->

					<div class="card-footer">
						<button type="submit" class="btn btn-primary btn-block">
							Rechercher
						</button>
					</div>
				</form>
			</div>
		</div>

		<div class="col-md-12 m-auto">
			<!-- general form elements -->
			<div class="card">
				<div class="card-header">
					<h3 class="card-title">Produit</h3>

					<div class="card-tools">
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="collapse"
							title="Collapse"
						>
							<i class="fas fa-minus"></i>
						</button>
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="remove"
							title="Remove"
						>
							<i class="fas fa-times"></i>
						</button>
					</div>
				</div>
				<div class="card-body p-0">
					<table class="table table-striped projects">
						<thead>
							<tr>
								<th style="width: 1%">#</th>
								<th style="width: 20%">Nom Client</th>
								<th style="width: 20%">Quantité</th>
								<th style="width: 16%">Montant payé</th>
								<th style="width: 20%" >Montant rendu</th>
								<th style="width: 20%" class="text-right">Total</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="order_detail in orders" :key="order_detail.id">
								<td>#</td>
								<td>
									{{ order_detail.client }}
								</td>
								<td>
									{{ order_detail.qty }}
								</td>
								<td class="project_progress">
									{{ order_detail.pay }} Fcfa
								</td>
								<td class="project-state">
									{{ order_detail.due }} Fcfa
								</td>
								<td class="project-actions text-right">
									{{ order_detail.total }} Fcfa
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!-- /.card-body -->
			</div>
		</div>
	</div>
</template>
<script>
import api from "../../../api/users";
import Csrf from "../../../api/Csrf";
import { Toast } from "../../../alert/SweetAlert";
import AppStorage from "../../../helpers/AppStorage";

export default {
	name: "SearchOrder",
	data() {
		return {
			form: {
				date: null,
			},
			orders: {},
			token: "",
		};
	},
	methods: {
		async search(e) {
			e.preventDefault();
			if (this.form.date.trim() !== "") {
				this.errors = [];
				try {
					await Csrf.getCoockie();
					const res = await api.post(
						"/api/order/search",
						JSON.stringify(this.form),
						{
							headers: { Authorization: `Bearer ${this.token}` },
						}
					);
					const data = await res.data;
					if (data) {
						this.orders = data;
					}
				} catch (error) {
					console.log(error);
				}
			} else {
				this.errors.push("La date est obligatoire");
				Toast.fire({
					icon: "warning",
					title: "Veuillez remplir la date",
				});
			}
		},
	},
	created() {
		if (!User.isLoggedIn() || User.isAdmin()) {
			this.$router.push("/");
		} else {
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
		}
	},
};
</script>