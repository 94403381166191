<template>
	<section class="vh-100">
		<div class="container py-5 h-100">
			<div class="row d-flex justify-content-center align-items-center h-100">
				<div class="col-12 col-md-8 col-lg-6 col-xl-5">
					<div class="card shadow-2-strong" style="border-radius: 1rem">
						<div class="card-body p-5">
							<h3 class="mb-5 text-center">Connectez-vous</h3>
							<form @submit="login">
								<div class="form-outline mb-4">
									<label class="form-label" for="typeEmailX">Email</label>
									<input
										type="email"
										id="typeEmailX"
										class="form-control form-control-lg"
										v-model="form.email"
									/>
								</div>

								<div class="form-outline mb-4">
									<label class="form-label" for="typePasswordX">Mot de Passe</label>
									<input
										type="password"
										id="typePasswordX"
										class="form-control form-control-lg"
										v-model="form.password"
									/>
								</div>
								<!-- Checkbox -->
								<div class="form-check d-flex justify-content-start mb-4">
									<input
										class="form-check-input"
										type="checkbox"
										value=""
										id="form1Example3"
										v-model="form.remember"
									/>
									<label class="form-check-label" for="form1Example3"> Gérant ? </label>
								</div>

								<input
									class="btn btn-primary btn-lg btn-block"
									type="submit"
									value="Se connecter"
								/>
								<br />
								<router-link to="/forget">Mot de passe oublié?</router-link>
								<br />
								<router-link to="/register">Créer un compte</router-link>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import api from "../../../api/users";
import Csrf from "../../../api/Csrf";
import { Toast } from "../../../alert/SweetAlert";

export default {
	name: "Login",
	data() {
		return {
			form: {
				email: "",
				password: "",
				remember: false,
			},
		};
	},
	methods: {
		emptyForm(form) {
			(form.email = ""), (form.password = ""), (form.remember = false);
		},
		async login(e) {
			e.preventDefault();
			if (
				this.form.email &&
				this.form.password &&
				this.form.password.trim() !== ""
			) {
				try {
					await Csrf.getCoockie();
					if (this.form.remember === true) {
						const res = await api.post("/api/auth/login", JSON.stringify(this.form));
						const data = await res.data;
						User.loginResponse(data);
						location.reload();
						this.$router.push("/dashboard");
					} else {
						const res = await api.post(
							"/api/auth/seller/login",
							JSON.stringify(this.form)
						);
						const data = await res.data;
						User.loginResponse(data);
						location.reload();
						this.$router.push("/pos");
					}
					Toast.fire({
						icon: "success",
						title: "Connexion réussie",
					});
					this.emptyForm(this.form);
				} catch (err) {
					Toast.fire({
						icon: "error",
						title: err.response.data.message,
					});
				}
			} else if (this.form.email) {
				Toast.fire({
					icon: "error",
					title: "Le mot de passe est obligatoire",
				});
			} else if (this.form.password) {
				Toast.fire({
					icon: "error",
					title: "L'email est obligatoire",
				});
			} else {
				Toast.fire({
					icon: "error",
					title: "Veuillez remplir tous les champs",
				});
			}
		},
	},
	created() {
		if (User.isLoggedIn()) {
			if (User.isAdmin()) {
				this.$router.push("/dashboard");
			} else {
				this.$router.push("/pos");
			}
		}
	},
};
</script>

<style scoped>
section {
	background-color: #007bff;
}
</style>
