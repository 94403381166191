<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container-fluid">
				<div class="row mb-2">
					<div class="col-sm-6">
						<router-link to="/store/deposits" class="btn btn-primary"
							>Voir les versements</router-link
						>
					</div>
					<div class="col-sm-6">
						<ol class="breadcrumb float-sm-right">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">Home</router-link>
							</li>
							<li class="breadcrumb-item active">Dépot bancaire</li>
						</ol>
					</div>
				</div>
			</div>
			<!-- /.container-fluid -->
		</section>
		<div class="container-fluid">
			<div class="col-md-12">
				<!-- general form elements -->
				<div class="card card-primary">
					<div class="card-header">
						<h3 class="card-title">Renseigner le dépot bancaire de la boutique</h3>
					</div>
					<!-- /.card-header -->
					<!-- form start -->
					<form @submit="addDeposit" enctype="multipart/form-data">
						<div class="card-body">
							<div class="row">
								<div class="col-sm-6">
									<div class="form-group">
										<label>Entrer la Date <span style="color: red">*</span></label>
										<input type="date" class="form-control" v-model="form.date" />
									</div>
								</div>

								<div class="col-sm-6">
									<div class="form-group">
										<label for="exampleSelectRounded0">Entrer le mantant<span style="color: red">*</span></label>
										<input type="text" class="form-control" v-model="form.amount" />
									</div>
								</div>
							</div>
						</div>
						<!-- /.card-body -->

						<div class="card-footer">
							<button type="submit" class="btn btn-primary btn-block">
								Ajouter
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import api from "../../../../api/users";
import Csrf from "../../../../api/Csrf";
import { Toast } from "../../../../alert/SweetAlert";
import AppStorage from "../../../../helpers/AppStorage";

export default {
	name: "AddDeposit",
	data() {
		return {
			form: {
				date: null,
				amount: null,
				seller_id: null
			},
			token: "",
		};
	},
	methods: {
		async addDeposit(e) {
			e.preventDefault();
			if (this.form.date.trim() !== "" && this.form.amount.trim() !== "") {
				this.errors = [];
				try {
					await Csrf.getCoockie();
					const res = await api.post(
						"/api/store-deposit",
						JSON.stringify(this.form),
						{
							headers: { Authorization: `Bearer ${this.token}` },
						}
					);
					const data = await res.data;
					if (data) {
						Toast.fire({
							icon: "success",
							title: data.message,
						});
						this.form = {};
					}
				} catch (error) {
					console.log(error);
				}
			} else {
				this.errors.push("La date est obligatoire");
				Toast.fire({
					icon: "warning",
					title: "Veuillez remplir la date",
				});
			}
		},
	},
	created() {
		if (!User.isLoggedIn() || User.isAdmin()) {
			this.$router.push("/");
		} else {
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
			this.form.seller_id = User.id()
		}
	},
};
</script>