<template>
	<!-- Content Wrapper. Contains page content -->
	<div class="content-wrapper">
		<!-- Content Header (Page header) -->
		<section class="content-header">
			<div class="container-fluid">
				<div class="row mb-2">
          <div class="col-sm-6"></div>
					<div class="col-sm-6">
						<ol class="breadcrumb float-sm-right">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">Home</router-link>
							</li>
							<li class="breadcrumb-item active">Commandes</li>
						</ol>
					</div>
				</div>
			</div>
			<!-- /.container-fluid -->
		</section>

		<!-- Main content -->
		<section class="content">
			<div class="col-md-9 m-auto">
				<!-- /.card -->
				<div class="card card-info">
					<div class="card-header">
						<h3 class="card-title">Liste des Commandes</h3>
						<div class="col-md-5 float-right">
							<input
								type="text"
								class="form-control"
								placeholder="Rechercher"
								v-model="searchItem"
							/>
						</div>
					</div>
					<h5 class="text-center" v-if="filterOrders.length === 0">
						Aucun résultat
					</h5>
					<div
						v-if="filterOrders.length !== 0"
						class="
							card-body
							p-0
							table-responsive-sm table-responsive-md table-responsive-xs
						"
					>
						<table class="table">
							<thead>
								<tr>
									<th>Client</th>
									<th>Payé</th>
									<th>Rendu</th>
                  <th>Quantité</th>
                  <th>Total</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="order in filterOrders" :key="order.id">
									<td>{{ order.client }}</td>
									<td>{{ order.pay }}</td>
									<td>{{ order.due }}</td>
                  <td>{{ order.qty }}</td>
                  <td>{{ order.total }}</td>
									<td class="text-right py-0 align-middle">
										<div class="btn-group btn-group-sm">
											<router-link
												:to="{ name: 'View-Order', params: { id: order.id } }"
												class="btn btn-sm bg-teal"
											>
												<i class="fas fa-eye"></i
											></router-link>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- /.card-body -->
				</div>
				<!-- /.card -->
			</div>
		</section>
		<!-- /.content -->
	</div>
	<!-- /.content-wrapper -->
</template>

<script>
import api from "../../../api/users";
import AppStorage from "../../../helpers/AppStorage";

export default {
	name: "ListOrder",
	data() {
		return {
			searchItem: "",
			token: "",
			orders: [],
		};
	},
	computed: {
		filterOrders() {
			return this.orders.filter((order) => {
				return (
					order.client.toUpperCase().match(this.searchItem.toUpperCase()) 
				);
			});
		},
	},
	methods: {
		async getOrders() {
			try {
				const res = await api.get("/api/orders", {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				this.orders = await res.data;
			} catch (err) {
				console.error(err);
			}
		},
	},
	created() {
		if (!User.isLoggedIn() || User.isAdmin()) {
			this.$router.push("/");
		} else {
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
			if (this.token) {
				this.getOrders();
			}
		}
	},
};
</script>