<template>
	<section class="vh-100 gradient-custom">
		<div class="container py-5 h-100">
			<div class="row justify-content-center align-items-center h-100">
				<div class="col-12 col-lg-9 col-xl-7">
					<div
						class="card shadow-2-strong card-registration"
						style="border-radius: 15px"
					>
						<div class="card-body p-4 p-md-5">
							<h3 class="mb-4 pb-2 pb-md-0 mb-md-5">Formulaire d'inscription</h3>
							<form @submit="register">
								<div class="row">
									<div class="col-md-6 mb-4">
										<div class="form-outline">
											<label class="form-label" for="firstName">
												Prénom
												<span style="color: red" title="Champ oligatoire">*</span></label
											>
											<input
												type="text"
												id="firstName"
												class="form-control form-control-lg"
												v-model="form.firstName"
												required
											/>
										</div>
									</div>
									<div class="col-md-6 mb-4">
										<div class="form-outline">
											<label class="form-label" for="lastName"
												>Nom
												<span style="color: red" title="Champ oligatoire">*</span></label
											>
											<input
												type="text"
												id="lastName"
												class="form-control form-control-lg"
												v-model="form.lastName"
												required
											/>
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col-md-6 mb-4 d-flex align-items-center">
										<div class="form-outline datepicker w-100">
											<label for="birthdayDate" class="form-label"
												>Date de Naissance</label
											>
											<input
												type="date"
												class="form-control form-control-lg"
												id="birthdayDate"
												v-model="form.dob"
											/>
										</div>
									</div>
									<div class="col-md-6 mb-4">
										<h6 class="mb-2 pb-1">Sexe:</h6>

										<div class="form-check form-check-inline">
											<input
												class="form-check-input"
												type="radio"
												name="inlineRadioOptions"
												id="femaleGender"
												value="femme"
												v-model="form.sex"
											/>
											<label class="form-check-label" for="femaleGender">Femme</label>
										</div>

										<div class="form-check form-check-inline">
											<input
												class="form-check-input"
												type="radio"
												name="inlineRadioOptions"
												id="maleGender"
												value="homme"
												v-model="form.sex"
											/>
											<label class="form-check-label" for="maleGender">Homme</label>
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col-md-6 mb-4 pb-2">
										<div class="form-outline">
											<label class="form-label" for="emailAddress"
												>Email
												<span style="color: red" title="Champ oligatoire">*</span></label
											>
											<input
												type="email"
												id="emailAddress"
												class="form-control form-control-lg"
												v-model="form.email"
												required
											/>
										</div>
									</div>
									<div class="col-md-6 mb-4 pb-2">
										<div class="form-outline">
											<label class="form-label" for="phoneNumber">Téléphone</label>
											<input
												type="tel"
												id="phoneNumber"
												class="form-control form-control-lg"
												v-model="form.phone"
											/>
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col-md-6 mb-4">
										<div class="form-outline">
											<label class="form-label" for="password">
												Mot de passe
												<span style="color: red" title="Champ oligatoire">*</span></label
											>
											<input
												type="password"
												id="password"
												class="form-control form-control-lg"
												v-model="form.password"
												required
											/>
										</div>
									</div>
									<div class="col-md-6 mb-4">
										<div class="form-outline">
											<label class="form-label" for="password_confirmation"
												>Confirmez le mot de passe
												<span style="color: red" title="Champ oligatoire">*</span></label
											>
											<input
												type="password"
												id="password_confirmation"
												class="form-control form-control-lg"
												v-model="form.password_confirmation"
												required
											/>
										</div>
									</div>
								</div>

								<div class="mt-4 pt-2">
									<input
										class="btn btn-primary btn-lg"
										type="submit"
										value="Créer un compte"
									/>
								</div>
								<br />
								<router-link to="/">Déjà inscrit ?</router-link>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { Toast } from "../../../alert/SweetAlert";
import api from "../../../api/users";
import Csrf from "../../../api/Csrf";

export default {
	name: "Register",
	data() {
		return {
			form: {
				firstName: "",
				lastName: "",
				dob: "",
				sex: "",
				email: "",
				phone: "",
				password: "",
				password_confirmation: "",
			},
			errors: {},
		};
	},
	methods: {
		async register(e) {
			e.preventDefault();
			if (
				this.form.firstName.trim() !== "" &&
				this.form.lastName.trim() !== "" &&
				this.form.email.trim() !== "" &&
				this.form.password.trim() !== "" &&
				this.form.password_confirmation.trim() !== ""
			) {
				try {
					await Csrf.getCoockie();
					const res = await api.post(
						"/api/auth/register",
						JSON.stringify(this.form)
					);
					const data = await res.data;
					if (data) {
						User.loginResponse(data);
						Toast.fire({
							icon: "success",
							title: "Inscription réussie",
						});
						this.$router.push("/dashboard");
					}
				} catch (error) {
					if (error.response.data.email[0]) {
						Toast.fire({
							icon: "error",
							title: error.response.data.email[0],
						});
					}
					Toast.fire({
						icon: "error",
						title: error.response.data.password[0],
					});
				}
			} else {
				Toast.fire({
					icon: "warning",
					title: "Veuillez remplir les champs obligatoires",
				});
			}
		},
	},
	created() {
		if (User.isLoggedIn()) {
			if (User.isAdmin()) {
				this.$router.push("/dashboard");
			} else {
				this.$router.push("/pos");
			}
		}
	},
};
</script>

<style scoped>
.gradient-custom {
	/* fallback for old browsers */
	background: #007bff;
}

.card-registration .select-input.form-control[readonly]:not([disabled]) {
	font-size: 1rem;
	line-height: 2.15;
	padding-left: 0.75em;
	padding-right: 0.75em;
}
.card-registration .select-arrow {
	top: 13px;
}
</style>
