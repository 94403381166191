import AppStorage from "./AppStorage";

import store from '../store'

class User {
    loginResponse(res) {
        return store.commit('setAuth', res)
    }
    hasToken() {
        const storedToken = AppStorage.getToken();
        if (storedToken) {
            return true 
        } else return false;
    }

    isLoggedIn() {
        return this.hasToken();
    }

    authUser() {
        if (this.isLoggedIn()) {
            return AppStorage.getUser();
        }
    }
    id() {
        if (this.isLoggedIn()) {
            if(store.state.user){
                return store.state.user.id
            }
            return false;
        }
        return false;
    }

    getStoreId() {
        if (this.isLoggedIn()) {
            if(store.state.user){
                return store.state.user.store_id
            }
            return false;
        }
        return false;
    }

    isAdmin() {
        if (this.isLoggedIn()) {
           if(store.state.user && parseInt(store.state.user.role) === 1 ){
                return true
            }
            return false
        }
        return false;
    }

    logout(){
        if(this.isLoggedIn()){
            store.commit('destroyStore')
            return AppStorage.clear()
        }
    }
}

export default User = new User();
