<template>
	<div class="content-wrapper">
		<div class="col-md-8 m-auto">
			<!-- general form elements -->
			<div class="card card-primary">
				<div class="card-header">
					<h3 class="card-title">Mise à jour d'un vendeur</h3>
				</div>
				<!-- /.card-header -->
				<!-- form start -->
				<form @submit="updateSeller" enctype="multipart/form-data">
					<div class="card-body">
						<div class="row">
							<div class="col-sm-6">
								<!-- text input -->
								<div class="form-group">
									<label>Prénom</label>
									<input
										type="text"
										class="form-control"
										placeholder="Entrer le prénom"
										v-model="form.firstName"
									/>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="form-group">
									<label>Nom</label>
									<input
										type="text"
										class="form-control"
										placeholder="Entrer le nom"
										v-model="form.lastName"
									/>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-sm-6">
								<!-- text input -->
								<div class="form-group">
									<label>Adresse</label>
									<input
										type="text"
										class="form-control"
										placeholder="Entrer l'adresse"
										v-model="form.address"
									/>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="form-group">
									<label>Téléphone</label>
									<input
										type="text"
										class="form-control"
										placeholder="Entrer le numéro de téléphone"
										v-model="form.phone"
									/>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-sm-6">
								<!-- Date -->
								<div class="form-group">
									<label>Date de recrutement:</label>
									<div class="input-group date">
										<input
											type="date"
											class="form-control datetimepicker-input"
											placeholder="jj/mm/yyyy"
											v-model="form.dor"
										/>
										<div class="input-group-append">
											<div class="input-group-text">
												<i class="fa fa-calendar"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="form-group">
									<label>Sexe:</label> <br />
									<div class="icheck-success d-inline">
										<input
											type="radio"
											value="femme"
											id="radioSuccess1"
											v-model="form.sex"
										/>
										<label for="radioSuccess1"> Femme &nbsp; </label>
									</div>
									<div class="icheck-success d-inline">
										<input
											type="radio"
											value="homme"
											id="radioSuccess2"
											v-model="form.sex"
										/>
										<label for="radioSuccess2"> Homme </label>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-6">
								<!-- text input -->
								<div class="form-group">
									<label>Email</label>
									<input
										type="email"
										class="form-control"
										placeholder="Entrer l'email"
										v-model="form.email"
									/>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="form-group">
									<label>Mot de passe</label>
									<input
										type="password"
										class="form-control"
										placeholder="Entrer le mot de passe"
										v-model="form.password"
									/>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-sm-6">
								<!-- text input -->
								<div class="form-group">
									<label>Numéro d'identité</label>
									<input
										type="text"
										class="form-control"
										placeholder="Entrer le numéro d'identité"
										v-model="form.nicn"
									/>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="form-group">
									<label for="exampleSelectRounded0">Boutique</label>
									<select class="custom-select rounded-0" v-model="form.store_id">
										<option v-for="store in stores" :key="store.id" :value="store.id">
											{{ store.store_name }}
										</option>
									</select>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-sm-8">
								<div class="form-group">
									<label for="exampleInputFile">Selectionner une photo</label>
									<div class="input-group">
										<div class="custom-file">
											<input
												type="file"
												class="custom-file-input"
												id="exampleInputFile"
												@change="getSellerPhoto"
											/>
											<label class="custom-file-label" for="exampleInputFile"
												>Choisir</label
											>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-4">
								<div class="form-group mt-4">
									<img :src="form.photo" alt="Photo du vendeur" height="40" width="40" />
								</div>
							</div>
						</div>
					</div>
					<!-- /.card-body -->

					<div class="card-footer">
						<button type="submit" class="btn btn-primary">Mettre à jour</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import Notifications from "../../../alert/Notification";
import api from "../../../api/users";
import { Toast } from "../../../alert/SweetAlert";
import AppStorage from "../../../helpers/AppStorage";
export default {
	name: "EditSeller",
	data() {
		return {
			form: {
				firstName: null,
				lastName: null,
				dor: null,
				sex: null,
				email: null,
				phone: null,
				password: null,
				salary: null,
				address: null,
				seller_photo: null,
				photo: null,
				nicn: null,
				store_id: null,
			},
			errors: {},
			stores: {},
			token: "",
		};
	},
	methods: {
		getSellerPhoto(event) {
			let photo = event.target.files[0];
			if (photo.size > 2000000) {
				Notifications.warning_imageSize.fire({
					icon: "error",
					title: "Oops...",
					text: "La taille ne doit pas dépasser 2 Mo",
				});
			} else {
				let reader = new FileReader();
				reader.onload = (event) => {
					this.form.photo = event.target.result;
				};
				reader.readAsDataURL(photo);
			}
		},
		async getSeller(id) {
			try {
				const res = await api.get("/api/seller/" + id, {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				const data = await res.data;
				if (data) {
					this.form = data;
				}
			} catch (err) {
				console.error(err);
			}
		},
		async updateSeller(e) {
			e.preventDefault();
			let id = this.$route.params.id;
			if (
				this.form.firstName.trim() !== "" &&
				this.form.lastName.trim() !== "" &&
				this.form.email.trim() !== ""
			) {
				try {
					const res = await api.patch(
						"/api/seller/" + id,
						JSON.stringify(this.form),
						{
							headers: { Authorization: `Bearer ${this.token}` },
						}
					);
					const data = await res.data.message;

					if (data) {
						Toast.fire({
							icon: "success",
							title: data,
						});
					}
					this.$router.push("/sellers");
				} catch (error) {
					console.log(error);
				}
			} else {
				Toast.fire({
					icon: "warning",
					title: "Veuillez remplir les champs obligatoires",
				});
			}
		},
		async getStores() {
			try {
				const res = await api.get("/api/store", {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				const data = await res.data;
				if (data) {
					this.stores = data;
				}
			} catch (err) {
				console.error(err);
			}
		},
	},
	created() {
		if (!User.isLoggedIn() || !User.isAdmin()) {
			this.$router.push("/");
		} else {
			let id = this.$route.params.id;
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
			if (this.token) {
				this.getSeller(id);
				this.getStores();
			}
		}
	},
};
</script>