<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container-fluid">
				<div class="row mb-2">
					<div class="col-sm-6">
						<router-link to="/orders" class="btn btn-primary"
							><i class="fas fa-arrow-left"></i> Retourner</router-link
						>
					</div>
					<div class="col-sm-6">
						<ol class="breadcrumb float-sm-right">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">Home</router-link>
							</li>
							<li class="breadcrumb-item active">Détails Commande</li>
						</ol>
					</div>
				</div>
			</div>
			<!-- /.container-fluid -->
		</section>
		<div class="col-md-12 m-auto">
			<!-- general form elements -->
			<div class="card">
				<div class="card-header">
					<h3 class="card-title">Détails de la commande</h3>
				</div>
				<div class="card-body p-0">
					<table class="table table-striped projects">
						<ul class="list-group">
              <li class="list-group-item">
								<strong>Numéro de Commande:</strong> {{ order_details[0] ? order_details[0].order_id: null}}
							</li>
							<li class="list-group-item">
								<strong>Client:</strong> {{ details.client }}
							</li>
							<li class="list-group-item">
								<strong>Montant payé:</strong> {{ details.pay }} Fcfa
							</li>
							<li class="list-group-item">
								<strong>Montant rendu:</strong> {{ details.due }} Fcfa
							</li>
							<li class="list-group-item">
								<strong>Quantité:</strong> {{ details.qty }}
							</li>
							<li class="list-group-item">
								<strong>Total:</strong> {{ details.total }} Fcfa
							</li>
							<li class="list-group-item">
								<strong>Date:</strong> {{ details.order_date }}
							</li>
						</ul>
					</table>
				</div>
				<!-- /.card-body -->
			</div>
		</div>

		<div class="col-md-12 m-auto">
			<!-- general form elements -->
			<div class="card">
				<div class="card-header">
					<h3 class="card-title">Produit</h3>

					<div class="card-tools">
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="collapse"
							title="Collapse"
						>
							<i class="fas fa-minus"></i>
						</button>
						<button
							type="button"
							class="btn btn-tool"
							data-card-widget="remove"
							title="Remove"
						>
							<i class="fas fa-times"></i>
						</button>
					</div>
				</div>
				<div class="card-body p-0">
					<table class="table table-striped projects">
						<thead>
							<tr>
								<th style="width: 1%">#</th>
								<th style="width: 20%">Nom</th>
								<th style="width: 30%">Code</th>
								<th>Image</th>
								<th style="width: 8%" class="text-center">Prix</th>
								<th style="width: 20%"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="order_detail in order_details" :key="order_detail.id">
								<td>#</td>
								<td>
									{{ order_detail.prod_name}}
								</td>
								<td>
									{{ order_detail.code}}
								</td>
								<td class="project_progress">
                  <img :src="path + order_detail.image" alt="Image_produit" height="40" width="40">
								</td>
								<td class="project-state">
									{{ order_detail.prod_price}}
								</td>
								<td class="project-actions text-right">
									<a class="btn btn-primary btn-sm" href="#">
										<i class="fas fa-folder"> </i>
										View
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!-- /.card-body -->
			</div>
		</div>
	</div>
</template>

<script>
import api from "../../../api/users";
import Csrf from "../../../api/Csrf";
import AppStorage from "../../../helpers/AppStorage";

export default {
	name: "EditProduct",
	data() {
		return {
			details: {},
			order_details: {},
			errors: {},
			token: "",
      path: ImagePath
		};
	},
	methods: {
		async getOrders(id) {
			try {
				await Csrf.getCoockie();
				const res = await api.get("/api/order/details/" + id, {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				const data = await res.data;
				if (data) {
					this.details = data;
				}
			} catch (err) {
				console.error(err);
			}
		},
		async getDetailOrders(id) {
			try {
				await Csrf.getCoockie();
				const res = await api.get("/api/detail-orders/" + id, {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				const data = await res.data;
				console.log(data);
				if (data) {
					this.order_details = data;
				}
			} catch (err) {
				console.error(err);
			}
		},
	},
	created() {
		if (!User.isLoggedIn() || User.isAdmin()) {
			this.$router.push("/");
		} else {
			let id = this.$route.params.id;
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
			if (this.token) {
				this.getOrders(id);
				this.getDetailOrders(id);
			}
		}
	},
};
</script>