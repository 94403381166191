<template>
	<div class="content-wrapper">
		<div>
			<div class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-6">
							<h1 class="m-0">Tableau de bord</h1>
						</div>
						<!-- /.col -->
						<div class="col-sm-6">
							<ol class="breadcrumb float-sm-right">
								<li class="breadcrumb-item">
									<a href="#">Home</a>
								</li>
								<li class="breadcrumb-item active">Dashboard</li>
							</ol>
						</div>
						<!-- /.col -->
					</div>
					<!-- /.row -->
				</div>
				<!-- /.container-fluid -->
			</div>
			<!-- /.content-header -->

			<!-- Main content -->
			<section class="content">
				<div class="container-fluid">
					<!-- Small boxes (Stat box) -->
					<div class="row">
						<div class="col-lg-3 col-6">
							<!-- small box -->
							<div class="small-box bg-info">
								<div class="inner">
									<h3>{{ todaySell }} <sup style="font-size: 20px">Fcfa</sup></h3>

									<p>Bénéfices</p>
								</div>
								<div class="icon">
									<i class="ion ion-bag"></i>
								</div>
								<router-link to="/store/income" class="small-box-footer"
									>Voir détails <i class="fas fa-arrow-circle-right"></i
								></router-link>
							</div>
						</div>
						<!-- ./col -->
						<div class="col-lg-3 col-6">
							<!-- small box -->
							<div class="small-box bg-success">
								<div class="inner">
									<h3>{{ income }}<sup style="font-size: 20px">Fcfa</sup></h3>

									<p>Transactions entrantes</p>
								</div>
								<div class="icon">
									<i class="ion ion-stats-bars"></i>
								</div>
								<router-link to="/store/income" class="small-box-footer"
									>Voir détails <i class="fas fa-arrow-circle-right"></i
								></router-link>
							</div>
						</div>
						<!-- ./col -->
						<div class="col-lg-3 col-6">
							<!-- small box -->
							<div class="small-box bg-warning">
								<div class="inner">
									<h3>{{ due }} <sup style="font-size: 20px">Fcfa</sup></h3>

									<p>Rendu</p>
								</div>
								<div class="icon">
									<i class="ion ion-person-add"></i>
								</div>
								<a href="#" class="small-box-footer"
									>More info <i class="fas fa-arrow-circle-right"></i
								></a>
							</div>
						</div>
						<!-- ./col -->
						<div class="col-lg-3 col-6">
							<!-- small box -->
							<div class="small-box bg-danger">
								<div class="inner">
									<h3>{{ expense }} <sup style="font-size: 20px">Fcfa</sup></h3>

									<p>Dépenses</p>
								</div>
								<div class="icon">
									<i class="ion ion-pie-graph"></i>
								</div>
								<router-link to="/expenses" class="small-box-footer"
									>More info <i class="fas fa-arrow-circle-right"></i
								></router-link>
							</div>
						</div>
						<!-- ./col -->
					</div>
				</div>
				<!-- /.container-fluid -->
			</section>
			<section class="content">
				<div class="col-md-12 m-auto">
					<!-- /.card -->
					<div class="card card-info">
						<div class="card-header">
							<h3 class="card-title">Liste des produits en rupture de stock</h3>
						</div>
						<h5 class="text-center" v-if="products.length === 0">Aucun résultat</h5>
						<div
							v-if="products.length !== 0"
							class="
								card-body
								p-0
								table-responsive-sm table-responsive-md table-responsive-xs
							"
						>
							<table class="table">
								<thead>
									<tr>
										<th>Nom</th>
										<th>Code</th>
										<th>Quantité</th>
										<th>Kilo</th>
										<th>Date d'expiration</th>
										<th>Etat en stock</th>
										<th>Image</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="product in products" :key="product.id">
										<td>{{ product.prod_name }}</td>
										<td>{{ product.code }}</td>
										<td>{{ product.quantity }}</td>
										<td>{{ product.kilo }}</td>
										<td>{{ product.expiration }}</td>
										<td v-if="product.quantity >= 2">
											<span class="badge badge-success">Disponible</span>
										</td>
										<td v-else><span class="badge badge-danger">Rupture</span></td>
										<td>
											<img
												:src="path + product.image"
												alt="photo_produit"
												width="40"
												height="40"
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<!-- /.card-body -->
					</div>
					<!-- /.card -->
				</div>
			</section>
		</div>
		<!-- /.content -->
	</div>
</template>

<script>
import api from "../../api/users";
import Csrf from "../../api/Csrf";
import AppStorage from "../../helpers/AppStorage";

export default {
	name: "Dashboard",
	created() {
		if (!User.isLoggedIn()) {
			this.$router.push({ name: "Login" });
		} else {
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
			if (this.token) {
				this.todaySells();
				this.todayIncomes();
				this.todayDues();
				this.todayExpenses();
				this.stockOut();
			}
		}
	},
	data() {
		return {
			todaySell: "",
			expense: "",
			due: "",
			income: "",
			token: "",
			products: {},
			path: ImagePath,
		};
	},
	methods: {
		async todaySells() {
			try {
				await Csrf.getCoockie();
				const res = await api.get("/api/today/sell", {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				const data = await res.data;
				if (data) {
					this.todaySell = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		async todayIncomes() {
			try {
				await Csrf.getCoockie();
				const res = await api.get("/api/today/income", {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				const data = await res.data;
				if (data) {
					this.income = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		async todayDues() {
			try {
				await Csrf.getCoockie();
				const res = await api.get("/api/today/due", {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				const data = await res.data;
				if (data) {
					this.due = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		async todayExpenses() {
			try {
				await Csrf.getCoockie();
				const res = await api.get("/api/today/expense", {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				const data = await res.data;
				if (data) {
					this.expense = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		async stockOut() {
			try {
				await Csrf.getCoockie();
				const res = await api.get("/api/today/stockout", {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				const data = await res.data;
				if (data) {
					this.products = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>