import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import User from "./helpers/User";
import axios from "axios";
window.User = User;
window.axios = axios
window.ImagePath = 'https://back.jolustan.com/';

window.axios.defaults.withCredentials = true;

const beforeCreated =  () => store.commit('initializeAuth')

createApp(App).use(store).use(router).use(beforeCreated()).mount('#app')
