<template></template>

<script>
import { Toast } from "../../../alert/SweetAlert";

export default {
	name: "Logout",
	created() {
		if (User.isLoggedIn()) {
			User.logout();
			Toast.fire({
				icon: "success",
				title: "Déconnecté",
			});
		}
		this.$router.push("/");
	},
};
</script>

<style scoped>
section {
	background-color: #508bfc;
}
</style>
