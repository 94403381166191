class AppStorage {
  storeToken(token) {
      localStorage.setItem("auth_user_token", JSON.stringify(token));
  }

  storeUser(user) {
      localStorage.setItem("auth_user", JSON.stringify(user));
  }

  storeRole(role) {
      localStorage.setItem("xcst", JSON.stringify(role));
  }

  store(token, user) {
      this.storeToken(token);
      this.storeUser(user);
  }

  clear() {
      localStorage.removeItem("auth_user_token");
      localStorage.removeItem("auth_user");
      return true
  }

  getToken() {
      return localStorage.getItem("auth_user_token");
  }

  getUser() {
      return localStorage.getItem("auth_user");
  }

  getRole(){
      return localStorage.getItem('xcst')
  }
}

export default AppStorage = new AppStorage();
