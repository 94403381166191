<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container-fluid">
				<div class="row mb-2">
					<div class="col-sm-6">
						<router-link to="/store/deposits" class="btn btn-primary"
							>Voir les dettes</router-link
						>
					</div>
					<div class="col-sm-6">
						<ol class="breadcrumb float-sm-right">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">Home</router-link>
							</li>
							<li class="breadcrumb-item active">MAJ-Dette</li>
						</ol>
					</div>
				</div>
			</div>
			<!-- /.container-fluid -->
		</section>
		<div class="container-fluid">
			<div class="col-md-12">
				<!-- general form elements -->
				<div class="card card-primary">
					<div class="card-header">
						<h3 class="card-title">Modification de la dette</h3>
					</div>
					<!-- /.card-header -->
					<!-- form start -->
					<form @submit="updateDette" enctype="multipart/form-data">
						<div class="card-body">
							<div class="row">
								<div class="col-sm-6">
									<div class="form-group">
										<label>Choisir le client <span style="color: red">*</span></label>
										<select
											class="custom-select rounded-0"
											id="exampleSelectRounded0"
											v-model="form.client_id"
										>
											<option
												v-for="client in clients"
												:key="client.id"
												:value="client.id"
											>
												{{ client.fullName }}
											</option>
										</select>
									</div>
								</div>

								<div class="col-sm-6">
									<div class="form-group">
										<label for="exampleSelectRounded0"
											>Entrer le mantant<span style="color: red">*</span></label
										>
										<input type="text" class="form-control" v-model="form.amount" />
									</div>
								</div>
							</div>
						</div>
						<!-- /.card-body -->

						<div class="card-footer">
							<button type="submit" class="btn btn-primary btn-block">Modifier</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import api from "../../../../api/users";
import Csrf from "../../../../api/Csrf";
import { Toast } from "../../../../alert/SweetAlert";
import AppStorage from "../../../../helpers/AppStorage";

export default {
	name: "Edit-Dette",
	data() {
		return {
			form: {
				amount: null,
				client_id: null,
			},
			clients: [],
			token: "",
		};
	},
	methods: {
		async getDette(id, seller_id) {
			try {
				const res = await api.get("/api/dette/" + id + "/" + seller_id, {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				this.form = await res.data;
			} catch (err) {
				console.error(err);
			}
		},
		async getCustomers() {
			try {
				const res = await api.get("/api/customer", {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				this.clients = await res.data;
			} catch (err) {
				console.error(err);
			}
		},
		async updateDette(e) {
			e.preventDefault();
			if (this.form.amount.trim() !== "" && this.form.client_id !== null) {
				this.errors = [];
				try {
					await Csrf.getCoockie();
					let id = this.$route.params.id;

					const res = await api.patch(
						"/api/dette/" + id + "/" + User.id(),
						JSON.stringify(this.form),
						{
							headers: { Authorization: `Bearer ${this.token}` },
						}
					);
					const data = await res.data;
					if (data) {
						Toast.fire({
							icon: "success",
							title: data.message,
						});
						this.form = {};
            this.$router.push('/store/dettes')
					}
				} catch (error) {
					console.log(error);
				}
			} else {
				this.errors.push("La date est obligatoire");
				Toast.fire({
					icon: "warning",
					title: "Veuillez remplir la date",
				});
			}
		},
	},
	created() {
		if (!User.isLoggedIn() || User.isAdmin()) {
			this.$router.push("/");
		} else {
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
			let id = this.$route.params.id;
			this.getDette(id, User.id());
			this.getCustomers();
		}
	},
};
</script>