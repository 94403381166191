<template>
	<!-- Content Wrapper. Contains page content -->
	<div class="content-wrapper">
		<!-- Content Header (Page header) -->
		<section class="content-header">
			<div class="container-fluid">
				<div class="row mb-2">
					<div class="col-sm-6">
					</div>
					<div class="col-sm-6">
						<ol class="breadcrumb float-sm-right">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">Home</router-link>
							</li>
							<li class="breadcrumb-item active">Dettes</li>
						</ol>
					</div>
				</div>
			</div>
			<!-- /.container-fluid -->
		</section>

		<!-- Main content -->
		<section class="content">
			<div class="col-md-9 m-auto">
				<!-- /.card -->
				<div class="card card-info">
					<div class="card-header">
						<h3 class="card-title">Liste des dettes des clients</h3>
						<div class="col-md-3 float-right">
							<input
								type="text"
								class="form-control"
								placeholder="Rechercher"
								v-model="searchItem"
							/>
						</div>
					</div>
					<h5 class="text-center" v-if="filterDettes.length === 0">
						Aucun résultat
					</h5>
					<div
						v-if="filterDettes.length !== 0"
						class="
							card-body
							p-0
							table-responsive-sm table-responsive-md table-responsive-xs
						"
					>
						<table class="table">
							<thead>
								<tr>
									<th>Client</th>
									<th>Montant</th>
									<th>Date de dette</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="dette in filterDettes" :key="dette.id">
									<td>{{ dette.fullName }}</td>
									<td>{{ dette.amount }} <strong>Fcfa</strong></td>
									<td>{{ dette.date_prise }}</td>
									<td class="text-right py-0 align-middle">
										<div class="btn-group btn-group-sm">
											<router-link
												:to="{ name: 'Edit-Dette', params: { id: dette.id } }"
												class="btn btn-sm bg-teal"
											>
												<i class="fas fa-edit"></i
											></router-link>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- /.card-body -->
				</div>
				<!-- /.card -->
			</div>
		</section>
		<!-- /.content -->
	</div>
	<!-- /.content-wrapper -->
</template>
<script>
import Swal from "sweetalert2";
import api from "../../../../api/users";
import AppStorage from "../../../../helpers/AppStorage";

export default {
	name: "List-Dettes",
	data() {
		return {
			dettes: [],
			searchItem: "",
			token: "",
		};
	},
	computed: {
		filterDettes() {
			return this.dettes.filter((dette) => {
				return (
					dette.date_prise.match(this.searchItem) ||
					dette.fullName.toUpperCase().match(this.searchItem.toUpperCase())
				);
			});
		},
	},
	methods: {
		async getDettes(id) {
			try {
				const res = await api.get("/api/dettes/" + id, {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				this.dettes = await res.data;
			} catch (err) {
				console.error(err);
			}
		},
	},
	created() {
		if (!User.isLoggedIn()) {
			this.$router.push("/");
		} else {
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
			if (this.token) {
				this.getDettes(User.id());
			}
		}
	},
};
</script>