<template>
	<aside
		class="main-sidebar sidebar-dark-primary elevation-4"
		id="aside-navbar"
		v-show="
			$route.path === '/' ||
			$route.path === '/login-seller' ||
			$route.path === '/login' ||
			$route.path === '/register' ||
			$route.path === '/forget'
				? false
				: true
		"
	>
		<!-- Brand Logo -->
		<router-link to="/dashboard" class="brand-link">
			<img
				src="/backend/dist/img/LogoEMAS.png"
				alt="AdminLTE Logo"
				class="brand-image img-circle"
				height="500"
				width="500"
			/>
			<span class="brand-text font-weight-light">EMAS</span>
		</router-link>

		<!-- Sidebar -->
		<div class="sidebar">
			<!-- Sidebar user panel (optional) -->
			<div class="user-panel mt-3 pb-3 mb-3 d-flex">
				<div class="" v-if="parseInt(user.role) !== 1">
					<img
						:src="path + user.seller_photo"
						class="rounded elevation-2"
						alt="User Image"
						height="90"
						width="90"
					/>
				</div>
				<div v-else></div>

				<div class="info text-uppercase">
					<a
						href="#"
						:class="parseInt(user.role) === 1 ? 'd-block h1 ' : 'd-block fs-5'"
						>{{ user.firstName + " " + user.lastName }}</a
					>
				</div>
			</div>

			<!-- Sidebar Menu -->
			<nav class="mt-2">
				<ul
					class="nav nav-pills nav-sidebar flex-column"
					data-widget="treeview"
					role="menu"
					data-accordion="false"
				>
					<!-- Add icons to the links using the .nav-icon class
                        with font-awesome or any other icon font library -->
					<li class="nav-item">
						<router-link to="/pos" class="nav-link">
							<i class="nav-icon fas fa-columns"></i>
							<p>Tableau de vente</p>
						</router-link>
					</li>

					<li class="nav-item" id="sellers">
						<a href="#" class="nav-link">
							<i class="nav-icon fas fa-th"></i>
							<p>
								Vendeurs
								<i class="fas fa-angle-left right"></i>
							</p>
						</a>
						<ul class="nav nav-treeview">
							<li class="nav-item">
								<router-link to="/add-seller" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Ajouter un vendeur</p>
								</router-link>
							</li>
							<li class="nav-item">
								<router-link to="/sellers" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Voir les vendeurs</p>
								</router-link>
							</li>
						</ul>
					</li>
					<li class="nav-item" id="suppliers">
						<a href="#" class="nav-link">
							<i class="nav-icon fas fa-chart-pie"></i>
							<p>
								Fournisseur
								<i class="right fas fa-angle-left"></i>
							</p>
						</a>
						<ul class="nav nav-treeview">
							<li class="nav-item">
								<router-link to="/add-supplier" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Ajouter un fournisseur</p>
								</router-link>
							</li>
							<li class="nav-item">
								<router-link to="/suppliers" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Voir les fournisseurs</p>
								</router-link>
							</li>
						</ul>
					</li>
					<li class="nav-item" id="categories">
						<a href="#" class="nav-link">
							<i class="nav-icon fas fa-tree"></i>
							<p>
								Client
								<i class="fas fa-angle-left right"></i>
							</p>
						</a>
						<ul class="nav nav-treeview">
							<li class="nav-item">
								<router-link to="/add-client" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Ajout de client</p>
								</router-link>
							</li>
							<li class="nav-item">
								<router-link to="/clients" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Voir les clients</p>
								</router-link>
							</li>
						</ul>
					</li>
					<li class="nav-item" id="categories">
						<a href="#" class="nav-link">
							<i class="nav-icon fas fa-tree"></i>
							<p>
								Catégorie
								<i class="fas fa-angle-left right"></i>
							</p>
						</a>
						<ul class="nav nav-treeview">
							<li class="nav-item">
								<router-link to="/add-category" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Ajout de catégorie</p>
								</router-link>
							</li>
							<li class="nav-item">
								<router-link to="/categories" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Voir les catégorie</p>
								</router-link>
							</li>
						</ul>
					</li>
					<li class="nav-item" id="products">
						<a href="#" class="nav-link">
							<i class="nav-icon fas fa-edit"></i>
							<p>
								Produits
								<i class="fas fa-angle-left right"></i>
							</p>
						</a>
						<ul class="nav nav-treeview">
							<li class="nav-item">
								<router-link to="/add-product" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Ajouter un produit</p>
								</router-link>
							</li>
							<li class="nav-item">
								<router-link to="/products" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Lister les produits</p>
								</router-link>
							</li>
							<li class="nav-item">
								<router-link to="/best-selling-products" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Les meilleurs produis</p>
								</router-link>
							</li>
						</ul>
					</li>
					<li class="nav-item">
						<a href="#" class="nav-link">
							<i class="nav-icon fas fa-table"></i>
							<p>
								Boutique
								<i class="fas fa-angle-left right"></i>
							</p>
						</a>
						<ul class="nav nav-treeview">
							<li class="nav-item">
								<router-link to="/add-store" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Ajouter une boutique</p>
								</router-link>
							</li>
							<li class="nav-item">
								<router-link to="/stores" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Voir les boutiques</p>
								</router-link>
							</li>
						</ul>
					</li>
					<li class="nav-header"><hr /></li>
					<li class="nav-item">
						<a href="#" class="nav-link">
							<i class="nav-icon far fa-envelope"></i>
							<p>
								Dépenses
								<i class="fas fa-angle-left right"></i>
							</p>
						</a>
						<ul class="nav nav-treeview">
							<li class="nav-item">
								<router-link to="/add-expense" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Ajouter une dépense</p>
								</router-link>
							</li>
							<li class="nav-item">
								<router-link to="/expenses" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Voir vos dépenses</p>
								</router-link>
							</li>
						</ul>
					</li>
					<li class="nav-item">
						<router-link to="/stock" class="nav-link">
							<i class="nav-icon far fa-image"></i>
							<p>Stock</p>
						</router-link>
					</li>
					<li class="nav-item">
						<a href="#" class="nav-link">
							<i class="nav-icon far fa-envelope"></i>
							<p>
								Commandes
								<i class="fas fa-angle-left right"></i>
							</p>
						</a>
						<ul class="nav nav-treeview">
							<li class="nav-item">
								<router-link to="/orders" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Commandes</p>
								</router-link>
							</li>
							<li class="nav-item">
								<router-link to="/order/search" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Voir les commandes</p>
								</router-link>
							</li>
						</ul>
					</li>
					<li class="nav-item">
						<router-link to="/store/dettes" class="nav-link">
							<i class="nav-icon fas fa-columns"></i>
							<p>Dette</p>
						</router-link>
					</li>
					<li class="nav-item">
						<a href="#" class="nav-link">
							<i class="nav-icon far fa-envelope"></i>
							<p>
								Versement Bancaire
								<i class="fas fa-angle-left right"></i>
							</p>
						</a>
						<ul class="nav nav-treeview">
							<li class="nav-item">
								<router-link to="/store/add-deposit" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Enregistrer un versement</p>
								</router-link>
							</li>
							<li class="nav-item">
								<router-link to="/store/deposits" class="nav-link">
									<i class="far fa-circle nav-icon"></i>
									<p>Voir les versements</p>
								</router-link>
							</li>
						</ul>
					</li>
					<li class="nav-item">
						<router-link to="/logout" class="nav-link">
							<i class="nav-icon far fa-plus-square"></i>
							<p>Déconnexion</p>
						</router-link>
					</li>
				</ul>
			</nav>
			<!-- /.sidebar-menu -->
		</div>
		<!-- /.sidebar -->
	</aside>
</template>
<script>
import AppStorage from "../../helpers/AppStorage";

export default {
	name: "SidebarNav",
	data() {
		return {
			user: {},
			path: ImagePath,
		};
	},
	created() {
		if (!User.isLoggedIn()) {
			this.$router.push({ name: "Login" });
		} else {
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
			if (this.token) {
				this.user = JSON.parse(AppStorage.getUser());
			}
		}
	},
};
</script>