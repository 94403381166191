<template>
	<nav
		class="main-header navbar navbar-expand navbar-white navbar-light"
		id="top-navbar"
		v-show="
			$route.path === '/' ||
			$route.path === '/login-seller' ||
			$route.path === '/login' ||
			$route.path === '/register' ||
			$route.path === '/forget'
				? false
				: true
		"
	>
		<!-- Left navbar links -->
		<ul class="navbar-nav">
			<li class="nav-item">
				<a class="nav-link" data-widget="pushmenu" href="#" role="button"
					><i class="fas fa-bars"></i
				></a>
			</li>
		</ul>
	</nav>
</template>
<script>
export default {
	name: "Main-Nav",
};
</script>