<template>
	<!-- Content Wrapper. Contains page content -->
	<div class="content-wrapper">
		<!-- Content Header (Page header) -->
		<section class="content-header">
			<div class="container-fluid">
				<div class="row mb-2">
					<div class="col-sm-6">
						<router-link to="/store/add-deposit" class="btn btn-primary"
							>Enregistrer un versement</router-link
						>
					</div>
					<div class="col-sm-6">
						<ol class="breadcrumb float-sm-right">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">Home</router-link>
							</li>
							<li class="breadcrumb-item active">Versements</li>
						</ol>
					</div>
				</div>
			</div>
			<!-- /.container-fluid -->
		</section>

		<!-- Main content -->
		<section class="content">
			<div class="col-md-9 m-auto">
				<!-- /.card -->
				<div class="card card-info">
					<div class="card-header">
						<h3 class="card-title">Liste des versements</h3>
						<div class="col-md-3 float-right">
							<input
								type="text"
								class="form-control"
								placeholder="Rechercher"
								v-model="searchItem"
							/>
						</div>
					</div>
					<h5 class="text-center" v-if="filterDeposits.length === 0">
						Aucun résultat
					</h5>
					<div
						v-if="filterDeposits.length !== 0"
						class="
							card-body
							p-0
							table-responsive-sm table-responsive-md table-responsive-xs
						"
					>
						<table class="table">
							<thead>
								<tr>
									<th>Vendeur</th>
									<th>Boutique</th>
									<th>Code Bank</th>
									<th>Montant</th>
									<th>Date</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="deposit in filterDeposits" :key="deposit.id">
									<td>{{ deposit.firstName }} {{ deposit.lastName }}</td>
									<td>{{ deposit.store_name }}</td>
									<td>{{ deposit.bank_code }}</td>
									<td>{{ deposit.amount }}</td>
									<td>{{ deposit.date }}</td>
									<td class="text-right py-0 align-middle">
										<div class="btn-group btn-group-sm">
											<router-link
												:to="{ name: 'Edit-Deposit', params: { id: deposit.id } }"
												class="btn btn-sm bg-teal"
											>
												<i class="fas fa-edit"></i
											></router-link>
											<a
												@click="deleteDeposit(deposit.id, deposit.seller_id)"
												class="btn btn-danger"
												><i class="fas fa-trash"></i
											></a>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- /.card-body -->
				</div>
				<!-- /.card -->
			</div>
		</section>
		<!-- /.content -->
	</div>
	<!-- /.content-wrapper -->
</template>
<script>
import Swal from "sweetalert2";
import api from "../../../../api/users";
import AppStorage from "../../../../helpers/AppStorage";

export default {
	name: "ListDeposits",
	data() {
		return {
			deposits: [],
			searchItem: "",
			token: "",
		};
	},
	computed: {
		filterDeposits() {
			return this.deposits.filter((deposit) => {
				return (
					deposit.date.match(this.searchItem) ||
					deposit.store_name.toUpperCase().match(this.searchItem.toUpperCase())
				);
			});
		},
	},
	methods: {
		async getDeposits(id) {
			try {
				const res = await api.get("/api/store-deposit/" + id, {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				this.deposits = await res.data;
			} catch (err) {
				console.error(err);
			}
		},
		deleteDeposit(id, seller_id) {
			Swal.fire({
				title: "Etes-vous sûr?",
				text: "La suppréssion est définitive!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Oui, Supprimer!",
				cancelButtonText: "Annuler",
			}).then(async (result) => {
				if (result.isConfirmed) {
					try {
						const res = await api.delete(
							"/api/store-deposit/" + id + "/" + seller_id,
							{
								headers: { Authorization: `Bearer ${this.token}` },
							}
						);
						const data = await res.data;
						const code = res.status;
						if (code === 201) {
							this.deposits = this.deposits.filter((deposit) => {
								return deposit.id !== id;
							});
							Swal.fire("Supprimé!", data.message, "success");
						}
					} catch (err) {
						Swal.fire("Erreur!", err, "error");
					}
				}
			});
		},
	},
	created() {
		if (!User.isLoggedIn()) {
			this.$router.push("/");
		} else {
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
			if (this.token) {
				this.getDeposits(User.id());
			}
		}
	},
};
</script>