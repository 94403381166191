<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container-fluid">
				<div class="row mb-2">
					<div class="col-sm-6">
						<router-link to="/stores" class="btn btn-primary"
							>Voir les boutiques</router-link
						>
					</div>
					<div class="col-sm-6">
						<ol class="breadcrumb float-sm-right">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">Home</router-link>
							</li>
							<li class="breadcrumb-item active">Ajout-Boutique</li>
						</ol>
					</div>
				</div>
			</div>
			<!-- /.container-fluid -->
		</section>
		<div class="col-md-6 m-auto">
			<!-- general form elements -->
			<div class="card card-primary">
				<div class="card-header">
					<h3 class="card-title">Ajout d'une boutique</h3>
				</div>
				<!-- /.card-header -->
				<!-- form start -->
				<form @submit="addStore" enctype="multipart/form-data">
					<div class="card-body">
						<div class="col-sm-12">
							<!-- text input -->
							<div class="form-group">
								<label>Nom de la Boutique <span style="color: red">*</span></label>
								<input
									type="text"
									:class="
										errors.length !== 0 ? 'form-control is-invalid' : 'form-control'
									"
									placeholder="Entrer le nom de la boutique"
									v-model="form.store_name"
									required
								/>
							</div>
						</div>
						<div class="col-sm-12">
							<!-- text input -->
							<div class="form-group">
								<label>Code Banque de la Boutique</label>
								<input
									type="text"
									class="form-control"
									placeholder="Entrer le code banque associé"
									v-model="form.bank_code"
								/>
							</div>
						</div>
						<div class="col-sm-12">
							<!-- text input -->
							<div class="form-group">
								<label>Adresse de la Boutique</label>
								<input
									type="text"
									class="form-control"
									placeholder="Entrer l'adresse"
									v-model="form.address"
								/>
							</div>
						</div>
					</div>
					<!-- /.card-body -->

					<div class="card-footer">
						<button type="submit" class="btn btn-primary">Ajouter</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
import api from "../../../api/users";
import { Toast } from "../../../alert/SweetAlert";
import AppStorage from "../../../helpers/AppStorage";

export default {
	name: "AddStore",
	data() {
		return {
			form: {
				store_name: "",
				bank_code: "",
				address: "",
			},
			errors: [],
			token: "",
		};
	},
	methods: {
		async addStore(e) {
			e.preventDefault();
			if (this.form.store_name.trim() !== "") {
				this.errors = [];
				try {
					const res = await api.post("/api/store", JSON.stringify(this.form), {
						headers: { Authorization: `Bearer ${this.token}` },
					});
					const data = await res.data;
					if (data) {
						Toast.fire({
							icon: "success",
							title: data.message,
						});
						this.form = {};
					}
				} catch (error) {
					Toast.fire({
						icon: "warning",
						title: error.response.data.message,
					});
				}
			} else {
				this.errors.push("Le nom est obligatoire");
				Toast.fire({
					icon: "warning",
					title: "Veuillez remplir le nom",
				});
			}
		},
	},
	created() {
		if (!User.isLoggedIn() || !User.isAdmin()) {
			this.$router.push("/");
		} else {
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
		}
	},
};
</script>