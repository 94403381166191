import { createStore } from 'vuex'
import AppStorage from '../helpers/AppStorage'

export default createStore({
  state: {
    authenticated: false,
    user: null
  },
  mutations: {
    setAuth(state, data){
      AppStorage.store(data.access_token, data.user)
      state.authenticated = true
      state.user = data.user
    },

    initializeAuth(state){
      if(AppStorage.getUser()){
        state.authenticated = true
        state.user = JSON.parse(AppStorage.getUser())
      }else{
        state.authenticated = false
        state.user = null
      }
    },
    destroyStore(state){
      state.authenticated = false
      state.user = null
    }
  },
  actions: {
  },
  modules: {
  }
})
