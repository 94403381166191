<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container-fluid">
				<div class="row mb-2">
					<div class="col-sm-6">
						<router-link to="/products" class="btn btn-primary"
							><i class="fas fa-arrow-left"></i> Voir les produuits</router-link
						>
					</div>
					<div class="col-sm-6">
						<ol class="breadcrumb float-sm-right">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">Home</router-link>
							</li>
							<li class="breadcrumb-item active">Ajout Produit</li>
						</ol>
					</div>
				</div>
			</div>
			<!-- /.container-fluid -->
		</section>
		<div class="col-md-8 m-auto">
			<!-- general form elements -->
			<div class="card card-primary">
				<div class="card-header">
					<h3 class="card-title">Ajout d'un produit</h3>
				</div>
				<!-- /.card-header -->
				<!-- form start -->
				<form @submit="addProduct" enctype="multipart/form-data">
					<div class="card-body">
						<div class="row">
							<div class="col-sm-6">
								<!-- text input -->
								<div class="form-group">
									<label>Nom du produit <span style="color: red">*</span></label>
									<input
										type="text"
										class="form-control"
										placeholder="Entrer le nom"
										v-model="form.prod_name"
									/>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="form-group">
									<label>Code</label>
									<input
										type="text"
										class="form-control"
										placeholder="Entrer le code"
										v-model="form.code"
									/>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-sm-6">
								<!-- text input -->
								<div class="form-group">
									<label>Prix d'achat</label>
									<input
										type="number"
										class="form-control"
										placeholder="Entrer l'adresse"
										v-model="form.buying_price"
										min="0"
									/>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="form-group">
									<label>Prix de vente <span style="color: red">*</span></label>
									<input
										type="number"
										class="form-control"
										placeholder="Entrer le numéro de téléphone"
										v-model="form.selling_price"
										min="0"
									/>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-6">
								<div class="form-group">
									<label for="exampleInputPassword1"
										>Quantité <span style="color: red">*</span></label
									>
									<input
										type="number"
										class="form-control"
										id="exampleInputPassword1"
										placeholder="Entrer la quantité"
										min="0"
										v-model="form.quantity"
									/>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="form-group">
									<label for="exampleInputPassword1">Kilo </label>
									<input
										type="text"
										class="form-control"
										id="exampleInputPassword1"
										placeholder="Entrer le nombre de kilo"
										min="0"
										v-model="form.kilo"
									/>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-sm-6">
								<!-- Date -->
								<div class="form-group">
									<label>Date d'achat:</label>
									<div class="input-group date">
										<input
											type="date"
											class="form-control datetimepicker-input"
											placeholder="jj/mm/yyyy"
											v-model="form.buying_date"
										/>
										<div class="input-group-append">
											<div class="input-group-text">
												<i class="fa fa-calendar"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-6">
								<!-- Date -->
								<div class="form-group">
									<label>Date d'expiration:</label>
									<div class="input-group date">
										<input
											type="date"
											class="form-control datetimepicker-input"
											placeholder="jj/mm/yyyy"
											v-model="form.expiration"
										/>
										<div class="input-group-append">
											<div class="input-group-text">
												<i class="fa fa-calendar"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-sm-6">
								<!-- text input -->
								<div class="form-group">
									<label for="exampleSelectRounded0">Catégorie</label>
									<select
										class="custom-select rounded-0"
										id="exampleSelectRounded0"
										v-model="form.category_id"
									>
										<option
											v-for="category in categories"
											:key="category.id"
											:value="category.id"
										>
											{{ category.name }}
										</option>
									</select>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="form-group">
									<label for="exampleSelectRounded0">Fournisseur</label>
									<select
										class="custom-select rounded-0"
										id="exampleSelectRounded0"
										v-model="form.supplier_id"
									>
										<option
											v-for="supplier in suppliers"
											:key="supplier.id"
											:value="supplier.id"
										>
											{{ supplier.sup_name }}
										</option>
									</select>
								</div>
							</div>
						</div>

						<div class="col-sm-12">
							<div class="form-group">
								<label for="exampleSelectRounded0">Boutique</label>
								<select
									class="custom-select rounded-0"
									id="exampleSelectRounded0"
									v-model="form.store_id"
								>
									<option v-for="store in stores" :key="store.id" :value="store.id">
										{{ store.store_name }}
									</option>
								</select>
							</div>
						</div>

						<div class="row">
							<div class="col-sm-8">
								<div class="form-group">
									<label for="exampleInputFile">Selectionner une image</label>
									<div class="input-group">
										<div class="custom-file">
											<input
												type="file"
												class="custom-file-input"
												id="exampleInputFile"
												@change="getProductImage"
											/>
											<label class="custom-file-label" for="exampleInputFile"
												>Choisir</label
											>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-4">
								<div class="form-group">
									<br />
									<div class="mt-2">
										<img
											:src="form.image"
											alt="Photo du vendeur"
											height="40"
											width="40"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- /.card-body -->

					<div class="card-footer">
						<button type="submit" class="btn btn-primary">Ajouter</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import Notifications from "../../../alert/Notification";
import api from "../../../api/users";
import { Toast } from "../../../alert/SweetAlert";
import AppStorage from "../../../helpers/AppStorage";

export default {
	name: "AddProduct",
	data() {
		return {
			form: {
				prod_name: "",
				code: "",
				quantity: 0,
				buying_date: "",
				selling_price: 0,
				buying_price: 0,
				image: "",
				category_id: null,
				supplier_id: null,
				expiration: null,
				store_id: null,
				kilo: null,
			},
			categories: {},
			suppliers: {},
			stores: {},
			errors: {},
			token: "",
		};
	},
	methods: {
		getProductImage(event) {
			let photo = event.target.files[0];
			if (photo.size > 2000000) {
				Notifications.warning_imageSize.fire({
					icon: "error",
					title: "Oops...",
					text: "La taille ne doit pas dépasser 2 Mo",
				});
			} else {
				let reader = new FileReader();
				reader.onload = (event) => {
					this.form.image = event.target.result;
				};
				reader.readAsDataURL(photo);
			}
		},
		async addProduct(e) {
			e.preventDefault();
			if (
				this.form.prod_name.trim() !== "" &&
				this.form.selling_price >= 0
			) {
				try {
					const res = await api.post("/api/product", JSON.stringify(this.form), {
						headers: { Authorization: `Bearer ${this.token}` },
					});
					Toast.fire({
						icon: "success",
						title: "Produit ajouté",
					});
					this.form = {};
				} catch (error) {
					console.log(error);
				}
			} else {
				Toast.fire({
					icon: "warning",
					title: "Veuillez remplir les champs obligatoires",
				});
			}
		},
		async getCategories() {
			try {
				const res = await api.get("/api/category", {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				const data = await res.data;
				if (data) {
					this.categories = data;
				}
			} catch (err) {
				console.error(err);
			}
		},
		async getStores() {
			try {
				const res = await api.get("/api/store", {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				const data = await res.data;
				if (data) {
					this.stores = data;
				}
			} catch (err) {
				console.error(err);
			}
		},
		async getSuppliers() {
			try {
				const res = await api.get("/api/supplier", {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				const data = await res.data;
				if (data) {
					this.suppliers = data;
				}
			} catch (err) {
				console.error(err);
			}
		},
	},
	created() {
		if (!User.isLoggedIn() || !User.isAdmin()) {
			this.$router.push("/");
		} else {
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
			if (this.token) {
				this.getCategories();
				this.getSuppliers();
				this.getStores();
			}
		}
	},
};
</script>