<template>
	<!-- Content Wrapper. Contains page content -->
	<div class="content-wrapper">
		<!-- Content Header (Page header) -->
		<section class="content-header">
			<div class="container-fluid">
				<div class="row mb-2">
					<div class="col-sm-6">
						<h1>Liste des vendeurs</h1>
					</div>
					<div class="col-sm-6">
						<ol class="breadcrumb float-sm-right">
							<li class="breadcrumb-item"><a href="#">Home</a></li>
							<li class="breadcrumb-item active">Vendeurs</li>
						</ol>
					</div>
				</div>
			</div>
			<!-- /.container-fluid -->
		</section>

		<!-- Main content -->
		<section class="content">
			<div class="col-md-3 mb-2 right-0">
				<input
					type="text"
					class="form-control"
					placeholder="Rechercher"
					v-model="searchItem"
				/>
			</div>
			<!-- Default box -->
			<div class="card card-solid">
				<div class="card-body pb-0">
					<div class="row">
						<div
							v-for="seller in filterSeller"
							:key="seller.id"
							class="col-12 col-sm-6 col-md-3 d-flex align-items-stretch flex-column"
						>
							<div class="card bg-light d-flex flex-fill">
								<div class="card-header text-muted border-bottom-0">
									Boutique {{ seller.store_id }}
								</div>
								<div class="card-body pt-0">
									<div class="row">
										<div class="col-7">
											<h2 class="lead">
												<b>{{ seller.firstName }} {{ seller.lastName }}</b>
											</h2>
											<p class="text-muted text-sm"><b>Detail: </b> Vendeur</p>
											<ul class="ml-4 mb-0 fa-ul text-muted">
												<li class="small">
													<span class="fa-li"><i class="fas fa-lg fa-building"></i></span>
													Adresse: {{ seller.address }}
												</li>
												<li class="small">
													<span class="fa-li"
														><i class="fas fa-lg fa-calendar-alt"></i
													></span>
													Travaille depuis le
													{{
														new Date(
															Date.UTC(
																new Date(seller.dor).getUTCFullYear(),
																new Date(seller.dor).getMonth(),
																new Date(seller.dor).getDate()
															)
														).toLocaleDateString("fr-FR", {
															year: "numeric",
															month: "long",
															day: "numeric",
														})
													}}
												</li>
												<li class="small">
													<span class="fa-li"><i class="fas fa-lg fa-phone"></i></span>
													Téléphone #: {{ seller.phone }}
												</li>
											</ul>
										</div>
										<div class="col-5 text-center">
											<img
												:src="path + seller.seller_photo"
												alt="user-avatar"
												class="img-circle img-fluid"
											/>
										</div>
									</div>
								</div>
								<div class="card-footer">
									<div class="text-right">
										<router-link
											:to="{ name: 'Edit-Seller', params: { id: seller.id } }"
											class="btn btn-sm bg-teal"
										>
											<i class="fas fa-edit"></i>
										</router-link>
										<a @click="deleteSeller(seller.id)" class="btn btn-sm btn-danger">
											<i class="fas fa-trash"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- /.card -->
		</section>
		<!-- /.content -->
	</div>
	<!-- /.content-wrapper -->
</template>

<script>
import Swal from "sweetalert2";
import api from "../../../api/users";
import AppStorage from "../../../helpers/AppStorage";

export default {
	name: "ListSellers",
	data() {
		return {
			sellers: [],
			searchItem: "",
			token: "",
			path: ImagePath,
		};
	},
	computed: {
		filterSeller() {
			return this.sellers.filter((seller) => {
				return (
					seller.firstName.toUpperCase().match(this.searchItem.toUpperCase()) ||
					seller.lastName.toUpperCase().match(this.searchItem.toUpperCase())
				);
			});
		},
	},
	methods: {
		async getSellers() {
			try {
				const res = await api.get("/api/seller", {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				this.sellers = await res.data;
			} catch (err) {
				console.error(err);
			}
		},
		deleteSeller(id) {
			Swal.fire({
				title: "Etes-vous sûr?",
				text: "La suppréssion est définitive!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Oui, Supprimer!",
				cancelButtonText: "Annuler",
			}).then(async (result) => {
				if (result.isConfirmed) {
					try {
						const res = await api.delete("/api/seller/" + id, {
							headers: { Authorization: `Bearer ${this.token}` },
						});
						const data = await res.data;
						const code = await res.status;
						if (code === 201) {
							this.sellers = this.sellers.filter((seller) => {
								return seller.id !== id;
							});
							Swal.fire("Supprimé!", data.message, "success");
						}
					} catch (err) {
						Swal.fire("Erreur!", err, "error");
					}
				}
			});
		},
	},
	created() {
		if (!User.isLoggedIn() || !User.isAdmin()) {
			this.$router.push("/");
		} else {
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
			if (this.token) {
				this.getSellers();
			}
		}
	},
};
</script>