<template>
	<div class="wrapper">
		<!-- Preloader Logo -->
		<div class="preloader flex-column justify-content-center align-items-center">
			<img
				class="animation__shake"
				src="/backend/dist/img/LogoEMAS.png"
				alt="EMAS"
				height="150"
				width="150"
			/>
		</div>

		<!-- Main Navbar -->
		<MainNav />

		<!-- Side Menu -->
		<SidebarNav :key="auth_check"/>

		<!-- Router view -->
		<router-view />
	</div>
</template>

<script>
import MainNav from "./components/back/MainNav.back.vue";
import SidebarNav from "./components/back/Sidebar-Nav.back.vue";

export default {
	name: "App",
	components: {
		MainNav,
		SidebarNav,
	},
	data() {
		return {
			auth_check: 0,
		};
	},
};
</script>
