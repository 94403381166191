<template>
	<!-- Content Wrapper. Contains page content -->
	<div class="content-wrapper">
		<!-- Content Header (Page header) -->
		<section class="content-header">
			<div class="container-fluid">
				<div class="row mb-2">
					<div class="col-sm-6">
						<router-link to="/add-expense" class="btn btn-primary"
							>Ajouter une dépense</router-link
						>
					</div>
					<div class="col-sm-6">
						<ol class="breadcrumb float-sm-right">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">Home</router-link>
							</li>
							<li class="breadcrumb-item active">Dépenses</li>
						</ol>
					</div>
				</div>
			</div>
			<!-- /.container-fluid -->
		</section>

		<!-- Main content -->
		<section class="content">
			<div class="col-md-9 m-auto">
				<!-- /.card -->
				<div class="card card-info">
					<div class="card-header">
						<h3 class="card-title">Liste des Dépenses</h3>
						<div class="col-md-5 float-right">
							<input
								type="text"
								class="form-control"
								placeholder="Rechercher"
								v-model="searchItem"
							/>
						</div>
					</div>
					<h5 class="text-center" v-if="filterExpense.length === 0">
						Aucun résultat
					</h5>
					<div
						v-if="filterExpense.length !== 0"
						class="
							card-body
							p-0
							table-responsive-sm table-responsive-md table-responsive-xs
						"
					>
						<table class="table">
							<thead>
								<tr>
									<th>Détails</th>
									<th>Montant</th>
									<th>Date</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="expense in filterExpense" :key="expense.id">
									<td>{{ expense.details }}</td>
									<td>{{ expense.amount }}</td>
									<td>{{ expense.expense_date }}</td>
									<td class="text-right py-0 align-middle">
										<div class="btn-group btn-group-sm">
											<router-link
												:to="{ name: 'Edit-Expense', params: { id: expense.id } }"
												class="btn btn-sm bg-teal"
											>
												<i class="fas fa-edit"></i
											></router-link>
											<a @click="deleteExpense(expense.id)" class="btn btn-danger"
												><i class="fas fa-trash"></i
											></a>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- /.card-body -->
				</div>
				<!-- /.card -->
			</div>
		</section>
		<!-- /.content -->
	</div>
	<!-- /.content-wrapper -->
</template>

<script>
import Swal from "sweetalert2";
import api from "../../../../api/users";
import AppStorage from "../../../../helpers/AppStorage";

export default {
	name: "ListExpense",
	data() {
		return {
			searchItem: "",
			token: "",
			expenses: [],
		};
	},
	computed: {
		filterExpense() {
			return this.expenses.filter((expense) => {
				return (
					expense.expense_date.toUpperCase().match(this.searchItem.toUpperCase()) ||
					expense.details.toUpperCase().match(this.searchItem.toUpperCase())
				);
			});
		},
	},
	methods: {
		async getExpenses() {
			try {
				const res = await api.get("/api/expense", {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				this.expenses = await res.data;
			} catch (err) {
				console.error(err);
			}
		},
		deleteExpense(id) {
			Swal.fire({
				title: "Etes-vous sûr?",
				text: "La suppréssion est définitive!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Oui, Supprimer!",
				cancelButtonText: "Annuler",
			}).then(async (result) => {
				if (result.isConfirmed) {
					try {
						const res = await api.delete("/api/expense/" + id, {
							headers: { Authorization: `Bearer ${this.token}` },
						});
						const data = await res.data;
						const code = res.status;
						if (code === 201) {
							this.expenses = this.expenses.filter((expense) => {
								return expense.id !== id;
							});
							Swal.fire("Supprimé!", data.message, "success");
						}
					} catch (err) {
						Swal.fire("Erreur!", err, "error");
					}
				}
			});
		},
	},
	created() {
		if (!User.isLoggedIn() || User.isAdmin()) {
			this.$router.push("/");
		} else {
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
			if (this.token) {
				this.getExpenses();
			}
		}
	},
};
</script>