<template>
	<div class="content-wrapper">
		<!-- Content Header (Page header) -->
		<section class="content-header">
			<div class="container-fluid">
				<div class="row mb-2">
					<div class="col-sm-6">
						<router-link to="/add-product" class="btn btn-primary"
							>Ajouter un produit</router-link
						>
					</div>
					<div class="col-sm-6">
						<ol class="breadcrumb float-sm-right">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">Home</router-link>
							</li>
							<li class="breadcrumb-item active">Produits</li>
						</ol>
					</div>
				</div>
			</div>
			<!-- /.container-fluid -->
		</section>

		<!-- Main content -->
		<section class="content">
			<div class="col-md-12 m-auto">
				<!-- /.card -->
				<div class="card card-info">
					<div class="card-header">
						<h3 class="card-title">Liste des produits</h3>
						<div class="col-md-3 float-right">
							<input
								type="text"
								class="form-control"
								placeholder="Rechercher"
								v-model="searchItem"
							/>
						</div>
					</div>
					<h5 class="text-center" v-if="filterProduct.length === 0">
						Aucun résultat
					</h5>
					<div
						v-if="filterProduct.length !== 0"
						class="
							card-body
							p-0
							table-responsive-sm table-responsive-md table-responsive-xs
						"
					>
						<table class="table">
							<thead>
								<tr>
									<th>Nome</th>
									<th>Quantité</th>
									<th>Kilo</th>
									<th>Prix de vente</th>
									<th>Date d'expiration</th>
									<th>Catégorie</th>
									<th>Image</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="product in filterProduct" :key="product.id">
									<td>{{ product.prod_name }}</td>
									<td>{{ product.quantity }}</td>
									<td>{{ product.kilo }}</td>
									<td>{{ product.buying_price }}</td>
									<td>{{ product.expiration }}</td>
									<td>{{ product.name }}</td>
									<td>
										<img
											:src="path + product.image"
											alt="photo_produit"
											width="40"
											height="40"
										/>
									</td>
									<td class="text-right py-0 align-middle">
										<div class="btn-group btn-group-sm">
											<router-link
												:to="{ name: 'Edit-Product', params: { id: product.id } }"
												class="btn btn-sm bg-teal"
											>
												<i class="fas fa-edit"></i
											></router-link>
											<a @click="deleteProduct(product.id)" class="btn btn-danger"
												><i class="fas fa-trash"></i
											></a>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<!-- /.card-body -->
				</div>
				<!-- /.card -->
			</div>
		</section>
		<!-- /.content -->
	</div>
</template>

<script>
import Swal from "sweetalert2";
import api from "../../../api/users";
import AppStorage from "../../../helpers/AppStorage";

export default {
	name: "ListProducts",
	data() {
		return {
			products: [],
			searchItem: "",
			token: "",
			path: ImagePath
		};
	},
	computed: {
		filterProduct() {
			return this.products.filter((product) => {
				return (
					product.name.toUpperCase().match(this.searchItem.toUpperCase()) ||
					product.prod_name.toUpperCase().match(this.searchItem.toUpperCase())
				);
			});
		},
	},
	methods: {
		async getProducts() {
			try {
				const res = await api.get("/api/product", {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				this.products = await res.data;
			} catch (err) {
				console.error(err);
			}
		},
		deleteProduct(id) {
			Swal.fire({
				title: "Etes-vous sûr?",
				text: "La suppréssion est définitive!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Oui, Supprimer!",
				cancelButtonText: "Annuler",
			}).then(async (result) => {
				if (result.isConfirmed) {
					try {
						const res = await api.delete("/api/product/" + id, {
							headers: { Authorization: `Bearer ${this.token}` },
						});
						const data = await res.data;
						const code = res.status;
						if (code === 201) {
							this.products = this.products.filter((product) => {
								return product.id !== id;
							});
							Swal.fire("Supprimé!", data.message, "success");
						}
					} catch (err) {
						Swal.fire("Erreur!", err, "error");
					}
				}
			});
		},
	},
	created() {
		if (!User.isLoggedIn() || !User.isAdmin()) {
			this.$router.push("/");
		} else {
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
			if (this.token) {
				this.getProducts();
			}
		}
	},
};
</script>