<template>
	<div class="content-wrapper">
		<section class="content-header">
			<div class="container-fluid">
				<div class="row mb-2">
					<div class="col-sm-6">
						<router-link to="/clients" class="btn btn-primary"
							>Voir les clients</router-link
						>
					</div>
					<div class="col-sm-6">
						<ol class="breadcrumb float-sm-right">
							<li class="breadcrumb-item">
								<router-link to="/dashboard">Home</router-link>
							</li>
							<li class="breadcrumb-item active">MAJ-CLient</li>
						</ol>
					</div>
				</div>
			</div>
			<!-- /.container-fluid -->
		</section>
		<div class="col-md-6 m-auto">
			<!-- general form elements -->
			<div class="card card-primary">
				<div class="card-header">
					<h3 class="card-title">Mise à jour d'un client</h3>
				</div>
				<!-- /.card-header -->
				<!-- form start -->
				<form @submit="updateCustomer" enctype="multipart/form-data">
					<div class="card-body">
						<div class="col-sm-12">
							<!-- text input -->
							<div class="form-group">
								<label>Nom du client <span style="color: red">*</span></label>
								<input
									type="text"
									:class="
										errors.length !== 0 ? 'form-control is-invalid' : 'form-control'
									"
									placeholder="Entrer le nom du client"
									v-model="form.fullName"
								/>
							</div>
						</div>
					</div>
					<!-- /.card-body -->

					<div class="card-footer">
						<button type="submit" class="btn btn-primary">Modifier</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
import api from "../../../api/users";
import { Toast } from "../../../alert/SweetAlert";
import AppStorage from "../../../helpers/AppStorage";

export default {
	name: "Edit-Client",
	data() {
		return {
			form: {
				fullName: "",
			},
			errors: [],
			token: "",
		};
	},
	methods: {
		async updateCustomer(e) {
			e.preventDefault();
			let id = this.$route.params.id;
			if (this.form.fullName.trim() !== "") {
				this.errors = [];
				try {
					const res = await api.patch(
						"/api/customer/" + id,
						JSON.stringify(this.form),
						{
							headers: { Authorization: `Bearer ${this.token}` },
						}
					);
					const data = await res.data;
					if (data) {
						Toast.fire({
							icon: "success",
							title: data.message,
						});
						this.form = {};
					}
				} catch (error) {
					Toast.fire({
						icon: "warning",
						title: error.response.data.message,
					});
				}
			} else {
				this.errors.push("Le nom est obligatoire");
				Toast.fire({
					icon: "warning",
					title: "Veuillez remplir le nom",
				});
			}
		},
		async getCustomer(id) {
			try {
				const res = await api.get("/api/customer/" + id, {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				const data = await res.data;
				if (data) {
					this.form = data;
				}
			} catch (err) {
				console.error(err);
			}
		},
	},
	created() {
		if (!User.isLoggedIn()) {
			this.$router.push("/");
		} else {
			let id = this.$route.params.id;
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
			if (this.token) {
				this.getCustomer(id);
			}
		}
	},
};
</script>