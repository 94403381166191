<template>
	<div class="content-wrapper">
		<div>
			<div class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-6">
							<h1 class="m-0">Tableau de vente</h1>
						</div>
						<div class="col-sm-6">
							<ol class="breadcrumb float-sm-right">
								<li class="breadcrumb-item">
									<a href="#">Home</a>
								</li>
								<li class="breadcrumb-item active">Tableau de vente</li>
							</ol>
						</div>
					</div>
				</div>
			</div>

			<section class="content">
				<div class="container-fluid">
					<div class="row">
						<section class="col-lg-6 connectedSortable">
							<div class="card">
								<div class="card-header">
									<h3 class="card-title">
										Achat
										<span style="color: red"
											>"Pour le Kilo utilisez le . et pas la , "</span
										>
									</h3>

									<div class="card-tools">
										<button
											type="button"
											class="btn btn-tool"
											data-card-widget="collapse"
											title="Collapse"
										>
											<i class="fas fa-minus"></i>
										</button>
										<button
											type="button"
											class="btn btn-tool"
											data-card-widget="remove"
											title="Remove"
										>
											<i class="fas fa-times"></i>
										</button>
									</div>
								</div>
								<div class="card-body p-0">
									<table class="table table-striped projects">
										<thead>
											<tr>
												<th style="width: 1%">#</th>
												<th style="width: 20%">Nom</th>
												<th style="width: 20%">Kilo</th>
												<th style="width: 20%">Quantité</th>
												<th>Unité</th>
												<th style="width: 19%" class="text-center">Total</th>
												<th style="width: 20%"></th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="cart in carts" :key="cart.id">
												<td>#</td>
												<td>{{ cart.prod_name }}</td>
												<td>
													<input
														type="text"
														v-model="cart.kilo"
														class="col-sm-12"
														@input="emitKilo(cart.kilo, cart.id, $event)"
														:disabled="cart.prod_quantity > 0 ? true : false"
													/>
												</td>
												<td>
													<input
														type="text"
														v-model="cart.prod_quantity"
														class="col-sm-4"
														@input="emitQuantity(cart.prod_quantity, cart.id, $event)"
														:disabled="cart.kilo > 0 ? true : false"
													/>
													<button
														@click.prevent="incrementCartItem(cart.id)"
														class="btn btn-sm btn-success mr-1"
														:disabled="cart.kilo > 0 ? true : false"
													>
														+
													</button>

													<button
														@click.prevent="decrementCartItem(cart.id)"
														class="btn btn-sm btn-danger"
														:disabled="cart.prod_quantity < 1 || cart.kilo > 0 ? true : false"
													>
														-
													</button>
												</td>
												<td class="project_progress">
													{{ cart.prod_price }} <strong>Fcfa</strong>
												</td>
												<td class="project-state">
													{{ cart.sub_total }} <strong>Fcfa</strong>
												</td>
												<td class="project-actions text-right">
													<a
														class="btn btn-info btn-sm"
														@click.prevent="removeCartItem(cart.id)"
													>
														<i class="fas fa-trash"> </i>
													</a>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div class="card-footer">
									<ul>
										<li
											class="
												list-group-item
												d-flex
												justify-content-between
												align-items-center
											"
										>
											Nombre d'articles/kilos:
											<strong>{{ qty }} <span v-if="qtyCheck > 0">Kg</span></strong>
										</li>
										<li
											class="
												list-group-item
												d-flex
												justify-content-between
												align-items-center
											"
										>
											Total:
											<strong>{{ total }} Fcfa</strong>
										</li>
									</ul>

									<br />
									<form @submit="orderDone">
										<div class="form-group">
											<label>Selectionner le client</label>
											<select
												class="form-control"
												style="width: 100%"
												v-model="form.client"
											>
												<option
													v-for="customer in customers"
													:key="customer.id"
													:value="customer.id"
												>
													{{ customer.fullName }}
												</option>
											</select>
										</div>
										<label>Payé</label>
										<input
											type="text"
											class="form-control"
											required
											placeholder="Montant payé par le client"
											v-model="form.pay"
											:min="total"
										/>
										<label>Reste</label>
										<input
											type="text"
											class="form-control"
											placeholder="Montant à remettre au client"
											:value="userPay"
											readonly
										/>
										<br />
										<button type="submit" class="btn btn-success">Valider</button>
									</form>
								</div>
								<!-- /.card-body -->
							</div>
						</section>
						<section class="col-lg-6 connectedSortable">
							<div class="card">
								<div class="card-header border-0">
									<h3 class="card-title">Produits</h3>
									<div class="col-md-9 float-right">
										<input
											type="text"
											class="form-control"
											placeholder="Rechercher"
											v-model="searchItem"
										/>
									</div>
								</div>

								<nav>
									<div class="nav nav-tabs" id="nav-tab" role="tablist">
										<button
											class="nav-link active"
											id="nav-home-tab"
											data-bs-toggle="tab"
											data-bs-target="#nav-home"
											type="button"
											role="tab"
											aria-controls="nav-home"
											aria-selected="true"
										>
											Tous les produits
										</button>

										<button
											class="nav-link"
											id="nav-profile-tab"
											data-bs-toggle="tab"
											data-bs-target="#nav-profile"
											type="button"
											role="tab"
											aria-controls="nav-profile"
											aria-selected="false"
											v-for="category in categories"
											:key="category.id"
											@click="getCategorizedProducts(category.id, uid)"
										>
											{{ category.name }}
										</button>
									</div>
								</nav>
								<div class="tab-content" id="nav-tabContent">
									<div
										class="tab-pane fade show active"
										id="nav-home"
										role="tabpanel"
										aria-labelledby="nav-home-tab"
									>
										<div class="card-body">
											<div class="row">
												<div
													class="col-md-3 col-lg-3 col-sm-6 col-6"
													v-for="product in filterProduct"
													:key="product.id"
												>
													<button
														:class="
															(product.quantity <= 1 && product.kilo <= 1) ||
															expirationProductDates(
																new Date(),
																new Date(product.expiration)
															) === false
																? 'btn btn-sm disabled'
																: 'btn btn-sm'
														"
														@click.prevent="addToCart(product)"
													>
														<div class="card col" style="width: 9rem">
															<img
																:src="path + product.image"
																id="em_photo"
																class="card-img-top"
																alt="photo_produit"
															/>
															<div class="card-body">
																<h6 class="card-title">{{ product.prod_name }}</h6>
																<br />
																<span
																	v-if="product.quantity > 0 || product.kilo > 0"
																	class="badge badge-success text-left"
																	>Disponible
																	<span v-if="product.quantity > 0">{{ product.quantity }}</span>
																	<span v-if="product.kilo > 0">{{ product.kilo }} kg</span>
																</span>
																<span v-else class="badge badge-danger"
																	>Rupture {{ product.quantity }}
																</span>
															</div>
															<div
																class="card-footer"
																v-if="
																	(product.quantity > 0 || product.kilo > 0) &&
																	expirationProductDates(
																		new Date(),
																		new Date(product.expiration)
																	) <= 35 &&
																	expirationProductDates(
																		new Date(),
																		new Date(product.expiration)
																	) !== false
																"
															>
																Expire dans:
																{{
																	expirationProductDates(
																		new Date(),
																		new Date(product.expiration)
																	)
																}}
																jours
															</div>
															<div
																class="card-footer bg-danger"
																v-if="
																	(product.quantity > 0 || product.kilo > 0) &&
																	expirationProductDates(
																		new Date(),
																		new Date(product.expiration)
																	) === false
																"
															>
																Produit expiré
															</div>
														</div>
													</button>
												</div>
											</div>
										</div>
									</div>
									<div
										class="tab-pane fade"
										id="nav-profile"
										role="tabpanel"
										aria-labelledby="nav-profile-tab"
									>
										<div class="card-body">
											<div class="row" v-if="categorizedP.length > 0">
												<div
													class="col-md-3 col-lg-3 col-sm-6 col-6"
													v-for="categorized in categorizedP"
													:key="categorized.id"
												>
													<button
														:class="
															(categorized.quantity <= 0 && categorized.kilo <= 0) ||
															expirationProductDates(
																new Date(),
																new Date(categorized.expiration)
															) === false
																? 'btn btn-sm disabled'
																: 'btn btn-sm'
														"
														@click.prevent="addToCart(categorized)"
													>
														<div class="card" style="width: 8.9rem">
															<img
																:src="path + categorized.image"
																id="em_photo"
																class="card-img-top"
																alt="photo_produit"
															/>
															<div class="card-body">
																<h6 class="card-title">{{ categorized.prod_name }}</h6>
																<br />
																<span
																	v-if="categorized.quantity > 0 || categorized.kilo > 0"
																	class="badge badge-success"
																	>Disponible
																	<span v-if="categorized.quantity > 0">{{
																		categorized.quantity
																	}}</span>
																	<span v-if="categorized.kilo > 0"
																		>{{ categorized.kilo }} kg</span
																	>
																</span>
																<span v-else class="badge badge-danger"
																	>Rupture {{ categorized.quantity }}
																</span>
															</div>
															<div
																class="card-footer"
																v-if="
																	(categorized.quantity > 0 || categorized.kilo > 0) &&
																	expirationProductDates(
																		new Date(),
																		new Date(categorized.expiration)
																	) <= 35 &&
																	expirationProductDates(
																		new Date(),
																		new Date(categorized.expiration)
																	) !== false
																"
															>
																Expire dans:
																{{
																	expirationProductDates(
																		new Date(),
																		new Date(categorized.expiration)
																	)
																}}
																jours
															</div>
															<div
																class="card-footer bg-danger"
																v-if="
																	(categorized.quantity > 0 || categorized.kilo > 0) &&
																	expirationProductDates(
																		new Date(),
																		new Date(categorized.expiration)
																	) === false
																"
															>
																Produit expiré
															</div>
														</div>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>
<script>
import api from "../../../api/users";
import AppStorage from "../../../helpers/AppStorage";
import { Toast } from "../../../alert/SweetAlert";
import Csrf from "../../../api/Csrf";
import Swal from "sweetalert2";

export default {
	name: "PosDashboard",
	created() {
		if (!User.isLoggedIn() || User.isAdmin()) {
			this.$router.push({ name: "Login" });
		} else {
			const test = AppStorage.getToken().split("|")[1];
			this.token = test.split('"')[0];
			if (this.token) {
				this.getProducts(User.id());
				this.getCategories();
				this.getCartProducts(User.id());
				this.uid = User.id();
				this.store_id = User.getStoreId();
				this.getCustomers();
			}
		}
	},
	data() {
		return {
			form: {
				client: null,
				pay: 0,
				due: 0,
			},
			store_id: null,
			kilo: 0,
			products: [],
			categories: [],
			categorizedP: [],
			carts: [],
			customers: [],
			searchItem: "",
			token: "",
			path: ImagePath,
			uid: null,
		};
	},
	computed: {
		userPay() {
			if (this.form.pay < this.total) {
				this.form.due = 0;
				return 0;
			}
			this.form.due = this.form.pay - this.total;
			return this.form.pay - this.total;
		},
		detteClient() {
			let dette = this.form.pay - this.total;
			if(dette < 0){
				dette = dette * (-1)
				return dette
			}
			return 0
		},

		filterProduct() {
			return this.products.filter((product) => {
				return (
					product.name.match(this.searchItem) ||
					product.prod_name.match(this.searchItem)
				);
			});
		},
		qty() {
			let sum = 0;
			for (let i = 0; i < this.carts.length; i++) {
				sum += parseFloat(this.carts[i].prod_quantity);
			}
			return sum;
		},
		qtyCheck() {
			let sum = 0;
			for (let i = 0; i < this.carts.length; i++) {
				if (parseFloat(this.carts[i].kilo) !== 0) {
					sum += parseFloat(this.carts[i].kilo);
				}
			}
			return sum;
		},
		total() {
			let sum = 0;
			for (let i = 0; i < this.carts.length; i++) {
				if (parseFloat(this.carts[i].kilo) === 0) {
					sum +=
						parseFloat(this.carts[i].prod_quantity) *
						parseFloat(this.carts[i].prod_price);
				} else if (parseFloat(this.carts[i].prod_quantity) === 0) {
					sum +=
						parseFloat(this.carts[i].kilo) * parseFloat(this.carts[i].prod_price);
				}
			}
			return sum;
		},
	},
	methods: {
		async emitKilo(kilo, id, evt) {
			if (kilo) {
				try {
					await Csrf.getCoockie();
					const res = await api.get(
						"/api/cart/kilo-update/" + id + "/" + User.id() + "/" + kilo,
						{
							headers: { Authorization: `Bearer ${this.token}` },
						}
					);
					this.getCartProducts(User.id());
				} catch (err) {
					console.log(err);
				}
			}
		},
		async emitQuantity(quantity, id, evt) {
			if (quantity) {
				try {
					await Csrf.getCoockie();
					const res = await api.get(
						"/api/cart/qty-update/" + id + "/" + User.id() + "/" + quantity,
						{
							headers: { Authorization: `Bearer ${this.token}` },
						}
					);
					this.getCartProducts(User.id());
				} catch (err) {
					console.log(err);
				}
			}
		},
		async orderDone(e) {
			e.preventDefault();
			if (this.total > 0) {
				let dataf = {
					kilo: this.qtyCheck,
					qty: this.qty,
					total: this.total,
					store_id: this.store_id,
					dette: this.detteClient !== 0 ? this.detteClient: 0,
					...this.form,
				};
				if (this.form.pay >= this.total) {
					try {
						await Csrf.getCoockie();
						const res = await api.post("/api/order-done", JSON.stringify(dataf), {
							headers: { Authorization: `Bearer ${this.token}` },
						});
						const data = await res.data;
						if (data) {
							Toast.fire({
								icon: "success",
								title: data.message,
							});
							this.form = {};
							this.getCartProducts(User.id());
							this.getProducts(User.id());
						}
					} catch (err) {
						Toast.fire({
							icon: "error",
							title: err.response.data.message,
						});
					}
				} else {
					Swal.fire({
						title: "Etes-vous sûr?",
						text: "Acceptez-vous la dette du client ?",
						icon: "warning",
						showCancelButton: true,
						confirmButtonColor: "#3085d6",
						cancelButtonColor: "#d33",
						confirmButtonText: "Oui",
						cancelButtonText: "Non",
					}).then(async (result) => {
						if (result.isConfirmed) {
							try {
								await Csrf.getCoockie();
								const res = await api.post("/api/order-done", JSON.stringify(dataf), {
									headers: { Authorization: `Bearer ${this.token}` },
								});
								const data = await res.data;
								if (data) {
									Toast.fire({
										icon: "success",
										title: data.message,
									});
									this.form = {};
									this.getCartProducts(User.id());
									this.getProducts(User.id());
								}
							} catch (err) {
								Toast.fire({
									icon: "error",
									title: err.response.data.message,
								});
							}
						}
					});
				}
			} else {
				Toast.fire({
					icon: "error",
					title: "Imposible d'éffectuer l'opération",
				});
			}
		},
		async getProducts(id) {
			try {
				await Csrf.getCoockie();
				const res = await api.get("/api/product/store/" + id, {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				this.products = await res.data;
			} catch (err) {
				console.error(err);
			}
		},
		async getCategories() {
			try {
				await Csrf.getCoockie();
				const res = await api.get("/api/category", {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				this.categories = await res.data;
			} catch (err) {
				console.error(err);
			}
		},
		async getCategorizedProducts(id, uid) {
			try {
				await Csrf.getCoockie();
				const res = await api.get("/api/categorized/product/" + id + "/" + uid, {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				const data = await res.data;
				if (data) {
					if (data.massage) {
						this.categorizedP = [];
					} else {
						this.categorizedP = data;
					}
				}
			} catch (err) {
				console.log(err);
			}
		},
		async addToCart(prod) {
			const user_id = User.id();
			try {
				await Csrf.getCoockie();
				const res = this.expirationProductDates(
					new Date(),
					new Date(prod.expiration)
				);
				if ((prod.quantity <= 1 && prod.kilo <= 1) || res === false) {
					Swal.fire({
						icon: "error",
						title: "Ce produit ne peut être ajouté",
					});
				} else {
					const resu = await api.get("/api/addToCart/" + prod.id + "/" + user_id, {
						headers: { Authorization: `Bearer ${this.token}` },
					});
					const code = await resu.status;
					if (code === 201) {
						this.getCartProducts(User.id());
					}
				}
			} catch (err) {
				console.log(err);
			}
		},
		async getCartProducts(user_id) {
			try {
				await Csrf.getCoockie();
				const resu = await api.get("/api/cart/products/" + user_id, {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				const data = await resu.data;
				if (data) {
					this.carts = data;
				}
			} catch (err) {
				console.log(err);
			}
		},
		expirationProductDates(date1, date2) {
			const daysIn = Math.round((date2 - date1) / (1000 * 60 * 60 * 24));
			if (daysIn <= 0) {
				return false;
			} else if (daysIn <= 35) {
				return daysIn;
			} else {
				return true;
			}
		},
		async removeCartItem(id) {
			try {
				await Csrf.getCoockie();
				const res = await api.delete(
					"/api/remove-cart-item/" + id + "/" + User.id(),
					{
						headers: { Authorization: `Bearer ${this.token}` },
					}
				);
				const data = await res.data;
				const code = res.status;
				if (code === 201) {
					this.carts = this.carts.filter((cart) => {
						return cart.id !== id;
					});
				}
			} catch (err) {
				console.log(err);
			}
		},
		async incrementCartItem(id) {
			try {
				await Csrf.getCoockie();
				const res = await api.get("/api/cart/increment/" + id + "/" + User.id(), {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				this.getCartProducts(User.id());
			} catch (err) {
				console.log(err);
			}
		},
		async decrementCartItem(id) {
			try {
				await Csrf.getCoockie();
				const res = await api.get("/api/cart/decrement/" + id + "/" + User.id(), {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				this.getCartProducts(User.id());
			} catch (err) {
				console.log(err);
			}
		},
		async getCustomers() {
			try {
				const res = await api.get("/api/customer", {
					headers: { Authorization: `Bearer ${this.token}` },
				});
				this.customers = await res.data;
			} catch (err) {
				console.error(err);
			}
		},
	},
};
</script>

<style scoped>
#em_photo {
	height: 100px;
	width: 135px;
}
</style>